import styled from "styled-components";

export const LayoutWrap = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: 0;
  background: #100f0f;
  margin: 0;

  .layout__main {
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    width: calc(100% - 60px);
    margin-left: 60px;
    overflow: hidden auto;
    background: #1d1d1d;
    position: relative;
    @media (max-width: 800px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;
