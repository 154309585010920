import styled from "styled-components";
import PopRev from "../../assets/pngs/poprev.png";
import { Modal, Select } from "antd";

export const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    max-width: 450px;
    min-height: 280px;
    background: #000;
    border-radius: 0;
    box-sizing: border-box;
  }
  .ant-modal-body {
    height: 100%;
  }
  .ant-modal-close-x {
    opacity: 0.7;
  }
  span {
    color: #fff;
    text-align: center;
  }
  .header {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    text-shadow: 0px 12px #ffffff33;

    span {
      color: #fcb316;
      text-shadow: 0px 12px #fcb31633;
    }
  }
  .bodyText {
    margin: 20px 0 30px 0;
    max-width: 250px;
    font-size: 12px;
  }
  button {
    margin-bottom: 40px;
  }
  .footer {
    height: 50px;
    border-top: 1px solid #707070;
    span {
      font-size: 10px;
    }
  }
`;

export const PopRevWrap = styled.div`
  width: 100%;
  background: linear-gradient(to right, #000000, #000000, #00000099, #00000099),
    url(${PopRev});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: max-content;
  min-height: 100vh;

  .content-wrap {
    padding: 100px 0;
  }

  @media (max-width: 800px) {
    background: linear-gradient(to right, #000000, #00000099), url(${PopRev});
    background-size: cover;
    background-position: center;

    .content-wrap {
      width: 100%;
      & > div {
        margin: 0;
        max-width: 700px;
      }
    }
  }

  .logo {
    img {
      width: 5%;
      margin: 20px 0;
      min-width: 100px;
    }
    @media (max-width: 800px) {
      margin-top: 50px;
    }
  }
  span {
    color: #fff;
  }
  .pay_you {
    font-weight: bold;
    font-size: 50px;
    line-height: 65px;
    margin: 20px 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    width: 100%;
    background: transparent;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 14px;
    color: #050bac;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #fcb31699 !important;
    font-family: "Avapore", sans-serif !important;
    margin-top: 3px;
    font-size: 10px;
  }
  .ant-select-selection-item {
    color: #fcb316 !important;
  }
  .ant-input,
  ::placeholder {
    font-size: 10px;
  }
  form {
    width: 100%;
  }
  .ant-form-item {
    width: 90%;
  }

  .ant-select-arrow {
    svg {
      color: #707070 !important;
      font-size: 10px;
    }
  }
  .ant-radio-wrapper {
    .ant-radio-inner {
      background: transparent;
      border-color: #707070 !important;
    }
    .ant-radio-inner:hover {
      border-color: #707070 !important;
    }
    .ant-radio-checked::after,
    .ant-radio-inner::after {
      border: 1px solid #fcb316 !important;
      background: #fcb316 !important;
    }
    .ant-radio-checked .ant-radio-inner {
      border: 1px solid #fcb316 !important;
      background: transparent;
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px transparent;
    }

    span {
      color: #707070 !important;
    }
  }
`;
