import gsap from "gsap";
import CSSRulePlugin from "gsap/CSSRulePlugin";

export const lineUpsIn = () => {
	const TL = gsap.timeline();
	gsap.registerPlugin(CSSRulePlugin);
	const line_ups = gsap.utils.selector("#line_ups");
	TL.set("#line_ups", { visibility: "visible" })
		.set(line_ups("[data-splitting]"), { visibility: "visible" })
		.to("#line_ups", {
			delay: 0.5,
			opacity: 1,
			duration: 1,
			ease: "circ.out",
		})
		.from(".static_text > .word, .static_text > .whitespace", {
			duration: 1,
			opacity: 0,
			yPercent: 75,
			stagger: 0.025,
			ease: "power2.out",
		});
};

export const lineUpsOut = () => {
	const TL = gsap.timeline();
	const line_ups = gsap.utils.selector("#line_ups");
	TL.to("#line_ups", {
		opacity: 0,
		duration: 0.75,
		ease: "circ.in",
	})
	.set("#line_ups", { visibility: "hidden" })
	.set(line_ups("[data-splitting]"), { visibility: "visible" });
};
