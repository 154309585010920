import styled from "styled-components";

export const NewsLetterWrapper = styled.section`
  width: 100%;
  height: 100%;
  background: #000000;
  position: relative;

  @media (max-width: 800px) {
    padding-top: 50px;
    background: #fcb316;
    & > div {
      align-items: flex-end;
    }
  }

  .top_section {
    background: #fff;
    min-height: 200px;
    padding: 0 2%;

    h2 {
      font-size: 30px;
      line-height: 1.35;
    }

    img {
      margin-left: 20px;
    }

    @media (max-width: 800px) {
      background: transparent;
      min-height: max-content;
      padding-top: 30px;

      img {
        display: none;
      }
    }

    @media (max-width: 500px) {
      padding: 30px 5%;
      h2 {
        font-size: 18px;
      }
    }
  }

  .bottom_section {
    background: #fcb316;
    min-height: calc(100% - 200px);
    padding: 30px 2%;
    position: relative;

    @media (max-width: 500px) {
      padding: 0 5%;
    }
    @media (max-width: 960px) {
      min-height: max-content;
      padding-bottom: 120px;
    }
    .patners {
      position: absolute;
      bottom: 20px;
      width: 400px;
      padding: 10px 10px;
      height: 100px;
      box-sizing: border-box;
      flex-wrap: nowrap;

      p {
        font-family: "Raitor", sans-serif !important;
        margin: 0 0 10px 0;
        font-size: 10px;
      }
      img {
        width: 100px;
        min-width: 70px;
        bottom: 0;
      }
      & div {
        justify-content: space-between;
        width: 90%;
        /* .mtn {
          width: 70px;
        } */
      }

      @media (max-width: 960px) {
        width: 100%;
        bottom: 0;
        left: 0;
      }
      @media (max-width: 450px) {
        & div {
          width: 100%;
        }
      }
    }
    h1 {
      font-size: 90px;
      color: #000000;
      line-height: 1.23;

      @media (max-width: 960px) {
        font-size: 60px;
        margin: 0;
        max-width: 550px;
      }
      @media (max-width: 500px) {
        font-size: 50px;
        text-align: center;
      }
    }

    .rightBottom {
      span {
        max-width: 400px;
        margin-top: -150px;
        @media (max-width: 500px) {
          text-align: center;
          max-width: 100%;
        }
      }

      @media (max-width: 960px) {
        justify-content: flex-start;
        max-width: 100%;

        span {
          max-width: 100%;
          margin-top: 0;
        }
      }
    }
  }

  .desktop {
    background: #fcb316 !important;
    @media (max-width: 800px) {
      display: none;
    }
  }
  .mobile {
    @media (min-width: 800px) {
      display: none;
    }
  }
`;
