import React, { useState, useContext } from "react";
import { AuthWrap } from "../../components/AuthWrap/styles";
import { FlexibleDiv } from "../../components/Box/styles";
import Davido from "../../assets/pngs/davido-login.png";
import LandingLayout from "../../components/Layout";
import { Typography, Form, notification } from "antd";
import Input from "../../components/TextField";
import Button from "../../components/Button";
import Logo from "../../assets/svgs/uduxLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import { loginUser } from "../../network/user";

const Login = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function handleFormSubmit(values) {
    setIsLoading(true);

    const payload = {
      ...values,
      email: values.email.toLowerCase(),
    };

    try {
      const data = await loginUser(payload);
      if (data.url) {
        navigate("/payments");
      } else {
        localStorage.setItem("user_token", data.token);
        localStorage.removeItem("user_object");
        localStorage.removeItem("active_plan");
        window.location.pathname = "/watch";
      }

      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.error,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
        console.log(error);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      }
    }
  }

  return (
    <LandingLayout>
      <AuthWrap>
        <FlexibleDiv height="100%" flexWrap="nowrap">
          <FlexibleDiv
            width="50%"
            flexDir="column"
            height="100%"
            alignItems="flex-start"
            className="left_section"
            flexWrap="nowrap"
          >
            <Typography.Title>
              Your <br />
              <span>Account</span>
            </Typography.Title>
            <span>
              Please login to your account using the details attached to your
              stream pass purchase.
            </span>
            <Form form={form} onFinish={handleFormSubmit}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input placeholder="EMAIL@udux.com" width="100%" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.Password placeholder="Password" width="100%" />
              </Form.Item>
              <FlexibleDiv
                justifyContent="space-between"
                width="400px"
                margin="10px 0"
              >
                <Button
                  width="180px"
                  height="50px"
                  htmlType="submit"
                  loading={isLoading}
                >
                  LOG IN
                </Button>
                <Button width="180px" height="50px" background="outlined">
                  <Link to="/forgot-password">Forgot Password</Link>
                </Button>
              </FlexibleDiv>
              <img src={Logo} alt="" className="logo" />
            </Form>
          </FlexibleDiv>
          <FlexibleDiv width="50%" className="righ_section">
            <img src={Davido} alt="" />
          </FlexibleDiv>
        </FlexibleDiv>
      </AuthWrap>
    </LandingLayout>
  );
};

export default Login;
