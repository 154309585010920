import gsap from "gsap";

export const glitchSceneIn = () => {
	const TL = gsap.timeline();
	TL.set("#glitch_scene", { visibility: "visible" })
  .to("#glitch_scene", {
		delay: 1.2,
		opacity: 1,
		duration: 1,
		ease: "circ.out",
	});
};

export const glitchSceneOut = () => {
	const TL = gsap.timeline();
	TL.to("#glitch_scene", {
		opacity: 0,
		duration: 0.75,
		ease: "circ.in",
	})
  .set("#glitch_scene", { visibility: "hidden" });
};
