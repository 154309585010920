import styled from "styled-components";
import LandingLayout from "../../components/Layout";

export const HomeLayout = styled(LandingLayout)`
  .layout__main {
    overflow: hidden;
    min-height: calc(100vh -60px);

    .home_section {
      position: absolute;
      left: 0;
      right: 0;
      &:not(.home_section:first-of-type) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
`;
