import React, { useEffect, useState } from "react";
import { LayoutWrap } from "./styles";
import SideBar from "./SideBar";
import Footer from "./Footer";
import Header from "./MobileHeader";

const LandingLayout = ({ className, children, scrollHandlers }) => {
  const [isMtnUser, setMtnUser] = useState();

  useEffect(() => {
    const mtnUser = localStorage.getItem("isMtn_user");
    setMtnUser(mtnUser);
  }, []);

  return (
    <LayoutWrap className={className} isMtnUser={isMtnUser}>
      <Header isMtnUser={isMtnUser} />
      <SideBar isMtnUser={isMtnUser} />
      <main
        className="layout__main"
        {...scrollHandlers?.mobile}
        onWheel={(e) => scrollHandlers?.desktop(e)}
      >
        {children}
      </main>
      <Footer isMtnUser={isMtnUser} />
    </LayoutWrap>
  );
};

export default LandingLayout;
