import styled from "styled-components";

export const AuthWrap = styled("div")`
  min-height: 120vh;
  height: max-content;
  width: 100%;
  background: #000;

  h3 {
    color: #fcb316;
    margin: 30px 0;
    text-align: center;
    h3 {
      margin: 10px 0 0 0;
    }
  }

  .righ_section {
    height: max-content;
    justify-content: flex-start;
    min-height: 120vh;
    span {
      color: #fff;
      text-align: center;
      max-width: 350px;
      padding-bottom: 30px;
      font-size: 12px;
    }

    h1 {
      color: #fff;
      font-size: 50px;
      text-align: center;
      border: 1px solid #707070;
      padding: 30px;
      width: 100%;
      margin: 0;

      span {
        color: #fcb316;
        font-size: 50px;
      }
      @media (max-width: 1070px) {
        font-size: 30px;

        span {
          font-size: 30px;
        }
      }
    }
    .confirmImage {
      border: 1px solid #fcb316;
      border-radius: 10px;
      width: 300px;
      height: 220px;
      margin-top: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    img {
      width: 70%;
      height: auto;
    }
  }

  .left_section {
    padding: 30px 3% 100px 3%;
    background: #1d1d1d;
    min-height: 120vh;
    align-items: flex-start;

    span {
      max-width: 400px;
      color: #fff;
    }
    h1 {
      color: #fff;
      font-size: 35px;
      margin: 40px 0 20px 0;
      text-align: center;
    }
    p {
      color: #fff;
      font-family: "Raitor", sans-serif !important;
      margin-left: 3px;
    }
    .activeNumber {
      color: #fcb316;
    }
    form {
      width: 100%;
      margin: 20px 0;
    }

    .logo {
      width: 20%;
      margin: 0;
      min-width: 100px;
    }
    .ant-row,
    .ant-form-item {
      width: 100%;
    }
    .shortInputSection {
      @media (min-width: 800px) {
        justify-content: space-between;
        .ant-row,
        .ant-form-item {
          width: 48%;
        }
      }
    }
    .longInputSection {
      @media (min-width: 800px) {
        width: 92%;
      }
    }

    .payment-cards {
      height: 220px;
      background: #f8f8f8;
      cursor: pointer;
      img {
        width: 60%;
      }
    }
    .active-card {
      outline: 5px solid #fcb316;
    }

    .goback {
      color: #fff;
      text-decoration: underline;
      cursor: pointer;
    }

    .confirmation_box {
      max-width: 600px;
      border: 1px solid #707070;
      span {
        max-width: 80%;
        text-align: center;
      }
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 800px) {
    & > div {
      position: relative;
      flex-direction: column;

      & > div {
      }
    }
    .righ_section {
      min-height: max-content;
      width: 100%;

      /* display: none; */
    }

    .left_section {
      width: 100%;
      min-height: max-content;
      padding: 80px 5%;

      .payment-cards {
        height: 150px;
      }
    }
    h1 {
      font-size: 40px;
    }
  }

  @media (max-width: 450px) {
    .left_section {
      button {
        width: 100%;
        margin-top: 15px;
      }
      .confirmation_box {
        button {
          width: 80% !important;
        }
      }
    }
    h1 {
      font-size: 30px;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    background: transparent;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 14px;
    color: #050bac;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #fcb31699 !important;
    font-family: "Avapore", sans-serif !important;
    margin-top: 5px;
  }
  .ant-select-selection-item {
    color: #fcb316 !important;
  }
  .ant-select-selection-search-input {
    margin-top: 5px !important;
    color: #fcb316 !important;
  }
  .ant-select-arrow {
    svg {
      color: #fcb31699 !important;
    }
  }
  .progressWrap {
    position: relative;

    ::before {
      width: 9px;
      content: "";
      height: 9px;
      border-radius: 100%;
      position: absolute;
      transition: all 0.25s ease;
      left: ${({ borderPosition }) => borderPosition - 1 + "%" || "0"};
      bottom: 5px;
      background: #fcb316;
      z-index: 1;
    }
  }
  .ant-progress-inner {
    background: transparent;
    border: 1px solid #665d5d;
    height: 6px;
  }
  .ant-progress-bg {
    margin-top: -2px;
    background: #fcb316;
  }

  .ant-checkbox-wrapper {
    margin-top: 15px;
    width: 100%;

    .ant-checkbox-checked .ant-checkbox-inner {
      background: transparent;
      border-color: #fcb316;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after,
    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fcb316;
    }
    .links {
      font-size: 12px;
      opacity: 0.7;
      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .ant-input-affix-wrapper {
    width: 100%;
    height: 40px;
    background: transparent;
    border-color: #707070;
    box-shadow: none;
    max-width: 100% !important;
    :hover,
    :focus,
    :active {
      border-color: #fcb316;
    }
    ::placeholder {
      color: #fcb316;
      opacity: 0.5 !important;
    }
    input {
      color: #fcb316;
      background: transparent !important;

      :active,
      :hover,
      :focus {
        background: transparent !important;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1d1d1d inset !important;
    -webkit-text-fill-color: #fcb316 !important;
  }

  .stripe {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #00000099;
    left: 0;
    top: 0;
    display: flex;
    z-index: 99999;
    align-items: center;

    form {
      width: 90%;
      max-width: 500px;
      background: #fff;
      padding: 40px 20px;
      border-radius: 10px;
    }
    svg {
      font-size: 30px;
      color: #707070;
    }
    .card {
      background-color: #fff;
      padding: 10px 20px 11px;
      border-radius: 5px;
      width: 100%;
      border: 1px solid #afafaf;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    }
    .pay-button {
      padding: 0.7rem 2rem;
      width: 100%;
      margin: 1rem 0;
      color: white;
      font-weight: bold;
      font-size: medium;
      background-color: #fcb316;
      border: 0;
      border-radius: 5px;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      transition: box-shadow 500ms;
      cursor: pointer;
    }
    .pay-button:disabled {
      background-color: #afafaf;
      box-shadow: none;
      cursor: default;
    }
    .pay-button:disabled:hover {
      box-shadow: none;
    }
    .pay-button:hover {
      box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;
