import axios from "axios";

export const instance = axios.create({
  baseURL:
    // "http://localhost:9000/api/v1",
    // "https://davido-new-backend.herokuapp.com/api/v1",
    "https://live.udux.com/api/v1",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("user_token")}` || null,
  },
});
