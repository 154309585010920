import { HomeLayout } from "./styles";
import HeroSection from "./HeroSection";
import ArenaLive from "./LiveFromTheArena";
import LineUps from "./Lineups";
import GlitchScene from "./GlitchScene";
import DavidoNeeds from "./DavidoNeeds";
import NewsLetter from "./NewsLetter";
import { useSwipeable } from "react-swipeable";
import React, { useEffect, useState } from "react";
import { heroSectionIn, heroSectionOut } from "../../animations/hero_section";
import { arenaLiveIn, arenaLiveOut } from "../../animations/arena_live";
import { lineUpsIn, lineUpsOut } from "../../animations/line_ups";
import { glitchSceneIn, glitchSceneOut } from "../../animations/glitch_scene";
import { davidoNeedsIn, davidoNeedsOut } from "../../animations/davido_needs";
import { newsLetterIn, newsLetterOut } from "../../animations/news_letter";

const LandingPage = () => {
  const [current, setCurrent] = useState(0);
  const [isMtnUser, setMtnUser] = useState();
  const [isScrolling, setIsScrolling] = useState(false);
  const [passScrollEventDown, setPassScrollEventDown] = useState(false);

  useEffect(() => {
    const mtnUser = localStorage.getItem("isMtn_user");
    setMtnUser(mtnUser);
  }, []);

  const up = (currentSection) => {
    switch (currentSection) {
      case 0:
        heroSectionOut();
        arenaLiveIn();
        break;
      case 1:
        arenaLiveOut();
        lineUpsIn();
        setPassScrollEventDown(true);
        break;
      case 2:
        lineUpsOut();
        glitchSceneIn();
        break;
      case 3:
        glitchSceneOut();
        davidoNeedsIn();
        break;
      case 4:
        davidoNeedsOut();
        newsLetterIn();
        break;

      default:
        setIsScrolling(false);
        break;
    }
  };

  const down = (currentSection) => {
    switch (currentSection) {
      case 1:
        arenaLiveOut();
        heroSectionIn();
        break;
      case 2:
        lineUpsOut();
        arenaLiveIn();
        break;
      case 3:
        setPassScrollEventDown(true);
        lineUpsIn();
        glitchSceneOut();
        break;
      case 4:
        glitchSceneIn();
        davidoNeedsOut();
        break;
      case 5:
        davidoNeedsIn();
        newsLetterOut();
        break;

      default:
        setIsScrolling(false);
        break;
    }
  };

  const handleScroll = (e) => {
    if (isScrolling || passScrollEventDown || e.deltaY === -0) return;
    setIsScrolling(true);
    if (e.deltaY > 0) {
      if (current < 0 || current > 4) {
        setIsScrolling(false);
        return;
      }
      up(current);
      setCurrent(current + 1);
    } else if (e.deltaY < 0) {
      if (current < 1 || current > 5) {
        setIsScrolling(false);
        return;
      }
      down(current);
      setCurrent(current - 1);
    }
    setTimeout(() => {
      setIsScrolling(false);
    }, 2000);
  };

  const swipeHandlers = useSwipeable({
    onSwiped: (e) => {
      if (isScrolling || passScrollEventDown || e.deltaY === -0) return;
      setIsScrolling(true);
      if (e.dir === "Up") {
        if (current < 0 || current > 4) {
          setIsScrolling(false);
          return;
        }
        up(current);
        setCurrent(current + 1);
      }
      if (e.dir === "Down") {
        if (current < 1 || current > 5) {
          setIsScrolling(false);
          return;
        }
        down(current);
        setCurrent(current - 1);
      }
      setTimeout(() => {
        setIsScrolling(false);
      }, 1500);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <HomeLayout
      scrollHandlers={{ desktop: handleScroll, mobile: swipeHandlers }}
    >
      <HeroSection isMtnUser={isMtnUser} />
      <ArenaLive isMtnUser={isMtnUser} />
      <LineUps
        isMtnUser={isMtnUser}
        passScrollEventDown={passScrollEventDown}
        setPassScrollEventDown={setPassScrollEventDown}
      />
      <GlitchScene isMtnUser={isMtnUser} />
      <DavidoNeeds isMtnUser={isMtnUser} />
      <NewsLetter isMtnUser={isMtnUser} />
      <div className="scroll">
        <span>Scroll</span>
      </div>
    </HomeLayout>
  );
};

export default LandingPage;
