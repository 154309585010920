import React, { useState, useContext, useEffect } from "react";
import { HeaderWrapper } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import { sideBarenuItems, mtnMenuItems } from "../../../utils/dataHelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiInstagram as Insta, FiTwitter as Twitter } from "react-icons/fi";
import { CgMenuRight as Menu } from "react-icons/cg";
import { MainContext } from "../../../context/MainContext";
import { CURRENT_USER } from "../../../context/types";

const Header = ({ isMtnUser }) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const active = location.pathname;
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);

  const show = () => {
    window.scrollTo(0, 0);
    setShowMenu(!showMenu);
  };
  const logout = async () => {
    localStorage.removeItem("user_token");
    await dispatch({ type: CURRENT_USER, payload: undefined });
    window.location.pathname = "/login";
  };

  return (
    <HeaderWrapper showMenu={showMenu}>
      <FlexibleDiv
        justifyContent="space-between"
        height="100%"
        flexWrap="nowrap"
        flexDir="column"
      >
        <FlexibleDiv justifyContent="space-between" className="header">
          {location.pathname === "/watch" || !!user ? (
            <p></p>
          ) : (
            <>
              {!isMtnUser ? (
                <Link to="/stream-passes">Buy Stream Passes</Link>
              ) : (
                <Link to="/watch" className="mtn">
                  Watch Stream
                </Link>
              )}
            </>
          )}

          <Menu onClick={show} className="menuIcon" />
        </FlexibleDiv>
        <FlexibleDiv
          className="menuItems"
          flexDir="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          flexWrap="nowrap"
        >
          {(!!isMtnUser || !!user ? mtnMenuItems : sideBarenuItems).map(
            (content, idx) => (
              <Link
                to={content.to}
                key={idx}
                data-glitch={content.item === "PopRev" ? "PopRev" : undefined}
                id={active === content.to ? "activeTab" : null}
                className={
                  !!isMtnUser && content.item === "Watch" ? "mtn" : null
                }
              >
                {content.item}
              </Link>
            )
          )}
          {isMtnUser === "" && (
            <>
              {!!user ? (
                <h5 className="auth" onClick={logout}>
                  Logout
                </h5>
              ) : (
                <>
                  <Link to="/login">Login</Link>
                  <Link to="/signup">Signup</Link>
                </>
              )}
            </>
          )}

          <FlexibleDiv className="iconsWrap" justifyContent="flex-start">
            <a href="https://www.instagram.com/uduxmusic/" target="_blank">
              <Insta />
            </a>
            <a href="https://twitter.com/udux_music" target="_blank">
              <Twitter />
            </a>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </HeaderWrapper>
  );
};

export default Header;
