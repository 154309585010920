import styled from "styled-components";
import { Input } from "antd";

export default styled(Input)`
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height || "58px"};
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background || "transparent"};
  border: ${({ border }) => border || "1px solid #707070"};
  opacity: 1;
  box-sizing: border-box;
  box-shadow: none !important;
  font-size: 16px;
  color: #fcb316;
  ::placeholder {
    color: #fcb316;
    opacity: 0.5;
  }
  :hover,
  :focus,
  :active {
    border-color: #fcb316;
  }
`;
