import React, { useEffect } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { GlitchSceneWrapper } from "./styles";

const GlitchScene = () => {
  useEffect(() => {
    const noContext = document.getElementById("noContextMenu");

    noContext.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      noContext.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    };
  }, []);

  return (
    <GlitchSceneWrapper id="glitch_scene" className="home_section">
      <FlexibleDiv height="100%" alignItems="flex-end">
        <FlexibleDiv
          justifyContent="flex-start"
          width="90%"
          className="obo_text"
        >
          <p>FROM OBO</p>
        </FlexibleDiv>
        <FlexibleDiv className="imageBox" id="noContextMenu">
          <video controls controlsList="nodownload">
            <source
              type="video/webm"
              src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/glitch_video.webm"
            />
            <source
              type="video/mp4"
              src={
                "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/glitch_video.mp4"
              }
            />
            Your browser does not support HTML5 video tag.
          </video>
        </FlexibleDiv>
      </FlexibleDiv>
    </GlitchSceneWrapper>
  );
};

export default GlitchScene;
