import styled from "styled-components";

export const GlitchSceneWrapper = styled.section`
  width: 100%;
  height: 100%;
  background: url("https://wrblo-live-fe.s3.us-east-2.amazonaws.com/square-blocks.png"), #000;
  background-size: 100% 100%;
  background-position: center;

  .imageBox {
    width: 85%;
    height: 90%;
    min-width: 280px;
    margin-bottom: 30px;
    video {
      width: 90%;
      height: 90%;
      aspect-ratio: 16 / 9;
    }
  }

  .obo_text {
    height: 10%;
    padding-top: 20px;
    p {
      color: #fcb316;
      font-size: 24px;
      margin: 0;
    }
    @media (min-width: 800px) {
      display: none;
    }
  }
  @media (max-width: 800px) {
    padding-top: 50px;
    & > div {
      align-items: flex-start;
    }
    .imageBox {
      width: 100%;
      margin-bottom: 0;
      height: 90%;
    }
  }
`;
