import styled from "styled-components";

export const AuthWrap = styled("div")`
  min-height: 115vh;
  width: 100%;

  .righ_section {
    min-height: 115vh;
    height: 100%;
    background: url("https://wrblo-live-fe.s3.us-east-2.amazonaws.com/square-blocks.png");
    background-size: cover;
    background-position: center;

    img {
      width: 80%;
      height: auto;
    }
  }

  .left_section {
    padding: 0 3%;
    span {
      max-width: 400px;
      color: #fff;
    }
    h1 {
      color: #fff;
      font-size: 70px;
      margin: 40px 0 20px 0;
      span {
        color: #fcb316;
      }
    }
    form {
      width: 100%;
      margin: 20px 0;
      padding-top: 40px;
      border-top: 1px solid #fcb31688;
    }

    .logo {
      width: 20%;
      margin: 30px 0 0 0;
      min-width: 100px;
    }
  }

  @media (max-width: 800px) {
    & > div {
      position: relative;
    }
    .righ_section {
      width: 100%;

      img {
        width: 100%;
        min-height: 100vh;
        object-fit: cover;
      }
    }

    .left_section {
      width: 100%;
      position: absolute;
      background: #000000cf;

      span {
        max-width: 400px;
        color: #fff;
      }
      h1 {
        font-size: 45px;
      }
    }
  }
  @media (max-width: 800px) and (max-height: 670px) {
    min-height: 120vh;

    .righ_section {
      img {
        min-height: 120vh;
      }
    }

    .left_section {
      padding: 100px 3%;
    }
  }

  @media (max-width: 450px) {
    .left_section {
      button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
  .ant-input-affix-wrapper {
    width: 100%;
    height: 50px;
    background: transparent;
    border-color: #707070;
    box-shadow: none;
    max-width: 100% !important;
    :hover,
    :focus,
    :active {
      border-color: #fcb316;
    }

    input {
      color: #fcb316;
      background: transparent !important;
      ::placeholder {
        color: #fcb316;
        opacity: 0.5 !important;
      }

      :active,
      :hover,
      :focus {
        background: transparent !important;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #100f0f inset !important;
    -webkit-text-fill-color: #fcb316 !important;
  }
`;
