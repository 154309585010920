import gsap from "gsap";

export const newsLetterIn = () => {
	const TL = gsap.timeline();
  const news_letter = gsap.utils.selector("#news_letter");
	TL.set("#news_letter", { visibility: "visible" })
  .set(news_letter("[data-splitting]"), { visibility: "visible" })
  .to("#news_letter", {
		delay: 0.75,
		opacity: 1,
		duration: 1,
		ease: "circ.out",
	})
  .from(news_letter(".inputContainer, .top_section img"), {
    opacity: 0,
    duration: 1,
    stagger: 0.05,
  }, 1)
  .from(news_letter(".word, .whitespace"), {
    duration: 0.5,
    opacity: 0,
    yPercent: 75,
    stagger: 0.01,
    ease: "power2.out",
  }, 1)
  .from(news_letter(".patners img"), {
    opacity: 0,
    duration: 0.5,
    stagger: 0.0025,
  }, 1.5);
};

export const newsLetterOut = () => {
	const TL = gsap.timeline();
  const news_letter = gsap.utils.selector("#news_letter");
	TL.to("#news_letter", {
		opacity: 0,
		duration: 0.75,
		ease: "circ.in",
	})
  .set("#news_letter", { visibility: "hidden" })
  .set(news_letter("[data-splitting]"), { visibility: "hidden" });
};
