import React, { useState } from "react";
import { AuthWrap } from "../../components/AuthWrap/styles";
import { FlexibleDiv } from "../../components/Box/styles";
import Davido from "../../assets/pngs/davido-resetPassword.png";
import LandingLayout from "../../components/Layout";
import { Typography, Form, notification } from "antd";
import Input from "../../components/TextField";
import Button from "../../components/Button";
import Logo from "../../assets/svgs/uduxLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { SmileOutlined } from "@ant-design/icons";
import { resetPassword } from "../../network/user";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  async function handleFormSubmit(values) {
    setIsLoading(true);

    const payload = {
      password: values.password,
    };

    try {
      const data = await resetPassword(values.token, payload);
      notification.open({
        message: "Success",
        description: "Password reset successful",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/login");
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.message,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
        console.log(error);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      }
    }
  }
  return (
    <LandingLayout>
      <AuthWrap>
        <FlexibleDiv height="100%" flexWrap="nowrap">
          <FlexibleDiv
            width="50%"
            flexDir="column"
            height="100%"
            alignItems="flex-start"
            className="left_section"
            flexWrap="nowrap"
          >
            <Typography.Title>
              Reset <br />
              <span>Password</span>
            </Typography.Title>
            <span>
              Please check your registered email for reset password token
            </span>
            <Form form={form} onFinish={handleFormSubmit}>
              <Form.Item rules={[{ required: true }]} name="token">
                <Input placeholder="Token" width="100%" />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="password">
                <Input placeholder="New Password" width="100%" />
              </Form.Item>
              <FlexibleDiv
                justifyContent="space-between"
                width="400px"
                margin="10px 0"
              >
                <Button
                  width="180px"
                  height="50px"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Reset PASSWORD
                </Button>
                <Button width="180px" height="50px">
                  <Link to="/login">LOG IN</Link>
                </Button>
              </FlexibleDiv>
              <img src={Logo} alt="" className="logo" />
            </Form>
          </FlexibleDiv>
          <FlexibleDiv width="50%" className="righ_section">
            <img src={Davido} alt="" />
          </FlexibleDiv>
        </FlexibleDiv>
      </AuthWrap>
    </LandingLayout>
  );
};

export default ResetPassword;
