import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  height: ${({ showMenu }) => (showMenu ? "100vh" : "50px")};
  box-sizing: border-box;
  transition: all 0.3s ease;
  position: ${({ showMenu }) => (showMenu ? "fixed" : "absolute")};
  background: #100f0f;
  left: 0;
  top: 0;
  border-bottom: 1px solid #707070;
  padding: 0 10px;
  z-index: 9;
  overflow: hidden;
  .header {
    height: 50px !important;
    padding: 15px 0;
    a {
      text-decoration: underline;
      margin: 0;
      color: white;
    }
    svg {
      color: white;
      font-size: 30px;
    }
  }
  .menuItems {
    padding: 50px 3%;
    box-sizing: border-box;
    height: calc(100vh - 50px);
    align-items: flex-start;

    a,
    svg,
    h5 {
      color: #fcb316;
      margin-bottom: 40px;
      font-size: 17px;
      &[data-glitch] {
        position: relative;
        &:before,
        &:after {
          content: attr(data-glitch);
          position: absolute;
          left: 0;
          opacity: 0.8;
        }
        &:after {
          color: #f0f;
          z-index: -2;
          animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
            reverse both;
        }
        &:before {
          color: #0ff;
          z-index: -1;
          animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
            both;
        }
      }
      @keyframes glitch {
        0% {
          transform: translate(0);
        }
        20% {
          transform: translate(-2px, 2px);
        }
        40% {
          transform: translate(-2px, -2px);
        }
        60% {
          transform: translate(2px, 2px);
        }
        80% {
          transform: translate(2px, -2px);
        }
        to {
          transform: translate(0);
        }
      }
    }
    svg {
      margin-right: 20px;
      font-size: 25px;
    }
  }
  @media (min-width: 800px) {
    display: none;
  }
`;
