import styled from "styled-components";

export const LineupsWrap = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
  display: flex;
  align-items: center;

  .carousel__section {
    position: relative;
    max-height: 100%;
    overflow: hidden;
    p {
      color: #fcb316;
      font-size: 20px;
    }
    span {
      color: #fff;
    }
    .celeb_titles {
      height: 125px;
      @media (min-width: 800px) {
        height: 0px;
      }
      h2 {
        position: absolute;
        font-size: 45px;
        color: #fcb316;
        line-height: 1.35;
        @media (min-width: 800px) {
          display: none;
        }
        &:nth-of-type(odd) span {
          color: #fcb316;
        }
        &:nth-of-type(even) span {
          color: #fff;
        }
        & > span, & > span > span {
          font-family: inherit;
        }
      }
    }
    
    h1 {
      width: 100%;
      position: absolute;
      bottom: 0;
      text-align: center;
      font-size: 100px;
      line-height: 100px;
      word-spacing: 60px;
      margin-bottom: 0;
      @media (max-width: 1080px) {
        font-size: 70px;
        line-height: 70px;
      }

      &:nth-of-type(odd) span {
        color: #fcb316;
      }
      &:nth-of-type(even) span {
        color: #fff;
      }
    }

    .celeb_info {
      position: relative;
      width: 100%;
      height: 250px;

      & > div:not(.celeb_titles) {
        position: absolute;
        width: 100%;
        height: max-content;
      }
    }

    .celeb_images {
      position: relative;
      height: 650px;
      max-height: calc(100vh - 60px);

      .image_wrap {
        position: absolute;
        width: 80%;
        max-height: 100%;

        img {
          width: 100%;
          height: auto;
          max-height: 100%;
        }
      }
    }

    ::before {
      position: absolute;
      right: 10%;
      top: 25%;
      content: "";
      height: 50%;
      width: 3px;
      background-color: #707070;
    }
    ::after {
      position: absolute;
      right: 10%;
      top: ${({ position }) => position + "%" || "1em"};
      content: "";
      height: 10%;
      width: 3px;
      z-index: 2;
      background-color: #fdb913;
      transition: top 0.75s ease-in-out;
    }

    @media (max-width: 800px) {
      padding-top: 50px;
      & > div {
        & > div {
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          justify-content: center;

          & > div {
            width: 100%;
          }
        }
      }
      .celeb_images {
        .image_wrap {
          width: 95%;
        }
      }
      .left_section {
        position: absolute;
        max-width: 100%;
        background: #00000099;
        padding: 0 5%;
        height: 100%;
        justify-content: space-between;
        z-index: 1;
      }
      p {
        color: #fff;
      }
      ::before,
      ::after,
      h1 {
        display: none;
      }
    }

    @media (max-width: 500px) {
      height: 80vh;
    }
  }
`;
