import { createContext } from "react";
import io from "socket.io-client";
const SOCKET_SERVER = "https://davido-socket-server.herokuapp.com/";
// const SOCKET_SERVER = "https://realtime.udux.com/";

const SocketContext = createContext();
const socket = io.connect(SOCKET_SERVER);

socket.on("connect", function () {});

const SocketContextProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
