import styled from "styled-components";

export const FooterWrap = styled.div`
  width: calc(100% - 60px);
  margin-left: 60px;
  height: 60px;
  border-top: 1px solid #707070;
  padding: 0 20px;
  background: #000;

  .watch_footer {
    .mtn_logo {
      width: 50px;
    }
    a {
      color: white;
      margin: 0 10px;
      text-decoration: underline;
      text-decoration-color: #707070;
      @media (max-width: 500px) {
        font-size: 8px;
      }
    }
  }

  .pause {
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }
  .music_playing {
    width: max-content;
    height: max-content;
    border-radius: 100%;
    z-index: 8;
    position: relative;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      transition: all 0.3s ease-in-out;
    }

    ::before {
      animation: ripple 2s linear infinite;
    }

    ::after {
      animation: ripple 2s linear 1s infinite;
    }

    @keyframes ripple {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.6);
        opacity: 1;
      }
      100% {
        transform: scale(1.9);
        opacity: 0;
      }
    }

    ::before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 15px;
      top: 15px;
      transform: translateX(-50%) translateY(-50%);
      display: block;
      width: 40px;
      height: 40px;
      border: 3px solid #ffffff35;
      transition: all 0.3s ease-in-out;
      border-radius: 50%;
      animation: lesson-card-pulse-border 1000ms ease-out infinite;
    }
  }

  @keyframes lesson-card-pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      border: 10px solid #ffffff50;
      width: 35px;
      height: 35px;
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  .barcode {
    svg {
      color: #fcb316;
      font-size: 20px;
      margin: 0 0 -5px -2px;
    }
    span {
      letter-spacing: 4px;
      font-size: 9px;
      text-align: center;
      color: #fcb316;
    }
  }

  .auth {
    margin: 0;
    color: white;
    font-family: "Raitor", sans-serif !important;
    cursor: pointer;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;

    & > div {
      justify-content: space-evenly;
    }

    .auth {
      display: none;
    }
  }
`;
