import { BrowserRouter, Route, Routes } from "react-router-dom";
import "splitting/dist/splitting.css";
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  useRef,
} from "react";
import LandingPage from "./routes/LandingPage";
import StreamPass from "./routes/StreamPass";
import ScrollToTop from "./components/ScrollManager";
import "antd/dist/antd.css";
import Login from "./routes/Login";
import ResetPassword from "./routes/ResetPassword";
import ForgotPassword from "./routes/ForgotPassword";
import LiveStream from "./routes/LiveStreamPage";
import PopRev from "./routes/PopRev";
import FAQ from "./routes/Faq";
import UserDetails from "./routes/SignupPage/FlowPages/userDetails";
import Payments from "./routes/SignupPage/FlowPages/paymentDetails";
import Confirmation from "./routes/SignupPage/FlowPages/confirmation";
import { Spin } from "antd";
import { getEnrichment } from "./network/user";
import { MainContext } from "./context/MainContext";
import { SocketContextProvider } from "./socket";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  const [pageLoading, setPageLoading] = useState(false);
  const ref = useRef(null);
  const {
    dispatch,
    state: { audioPlay },
  } = useContext(MainContext);

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
    handleResize();
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [vh]);

  useEffect(() => {
    document.onreadystatechange = function () {
      if (document.readyState !== "complete") {
        setPageLoading(true);
      } else {
        setPageLoading(false);
      }
    };
    return () => {
      document.onreadystatechange = function () {
        setPageLoading(false);
      };
    };
  }, []);

  useEffect(() => {
    if (!!audioPlay) {
      ref?.current?.play();
    } else ref?.current?.pause();
  }, [audioPlay]);

  useLayoutEffect(() => {
    async function fetchData() {
      setPageLoading(true);

      try {
        const data = await getEnrichment();
        if (!!data?.isMtnCustomer) {
          localStorage.setItem("isMtn_user", true);
        } else {
          localStorage.setItem("isMtn_user", "");
        }
        setPageLoading(false);
      } catch (e) {
        console.log(e);
        setPageLoading(false);
      }
    }
    fetchData();
    return () => {
      setPageLoading(false);
    };
  }, []);

  if (pageLoading) {
    return (
      <div
        style={{
          height: "100vh",
          background: "#000000",
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <SocketContextProvider>
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route path="/stream-passes" element={<StreamPass />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/poprev" element={<PopRev />} />
                <Route path="/faqs" element={<FAQ />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<UserDetails />} />
                <Route
                  path="/payment-confirmation"
                  element={<Confirmation />}
                />
                <Route path="/payments" element={<Payments />} />
                <Route path="/watch" element={<LiveStream />} />
                <Route path="/" element={<LandingPage />} />
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
          <audio
            className="audio"
            ref={ref}
            controls
            loop
            src={
              "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/audio.mp3"
            }
            type="audio/mpeg"
            style={{
              visibility: "hidden",
              position: "absolute",
              zIndex: "-9999",
              top: "0",
            }}
          ></audio>
        </SocketContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
