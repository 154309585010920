import React, { useRef, useState, useEffect, useContext } from "react";
import { MdPauseCircle as Pause, MdPlayCircle as Play } from "react-icons/md";
import { FooterWrap } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import { RiBarcodeLine as BarCode } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/pngs/mtn.jpg";
import { MainContext } from "../../../context/MainContext";
import { AUDIO_PLAY, CURRENT_USER } from "../../../context/types";
import { getCurrentUser } from "../../../network/user";
import { LoadingOutlined } from "@ant-design/icons";

const Footer = ({ isMtnUser }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [userActive, setUserActive] = useState(false);
  const {
    dispatch,
    state: { audioPlay, user },
  } = useContext(MainContext);

  const location = useLocation();
  const activePath = location.pathname;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const userToken = localStorage.getItem("user_token");

      if (!!userToken) {
        try {
          const data = await getCurrentUser();

          await dispatch({
            type: CURRENT_USER,
            payload: data,
          });
          setLoading(false);
        } catch (e) {
          setLoading(false);
          await dispatch({
            type: CURRENT_USER,
            payload: undefined,
          });
        }
      } else {
        setLoading(false);

        await dispatch({
          type: CURRENT_USER,
          payload: undefined,
        });
      }
    }

    fetchData();
  }, []);

  const logout = async () => {
    localStorage.removeItem("user_token");
    await dispatch({ type: CURRENT_USER, payload: undefined });
    window.location.pathname = "/login";
  };

  const toggle = () => {
    dispatch({ type: AUDIO_PLAY, payload: !audioPlay });
    setPlaying(!playing);
  };

  return (
    <FooterWrap>
      {activePath === "/watch" ? (
        <FlexibleDiv
          height="100%"
          justifyContent="flex-start"
          className="watch_footer"
        >
          <img src={Logo} alt="" className="mtn_logo" />
          <a>Top up: Dial *131# and Borrow Credit : *606#</a>
        </FlexibleDiv>
      ) : (
        <FlexibleDiv height="100%" justifyContent="space-between">
          <div className={!audioPlay && "music_playing"} onClick={toggle}>
            {!!audioPlay ? (
              <Pause className="pause" />
            ) : (
              <Play className="pause" />
            )}
          </div>
          <FlexibleDiv width="max-content" className="barcode">
            <FlexibleDiv flexDir="column" width="max-content">
              <span>
                <BarCode />
                <BarCode />
              </span>
              <span>LIVE</span>
            </FlexibleDiv>
            &nbsp;&nbsp;
            <FlexibleDiv flexDir="column" width="max-content">
              <span>
                <BarCode />
                <BarCode />
                <BarCode />
                <BarCode />
              </span>
              <span>STREAM</span>
            </FlexibleDiv>
            &nbsp;&nbsp;
            <FlexibleDiv flexDir="column" width="max-content">
              <span>
                <BarCode />
                <BarCode />
                <BarCode />
                <BarCode />
              </span>
              <span>05/03/22</span>
            </FlexibleDiv>
          </FlexibleDiv>
          {!!isMtnUser ? (
            <p className="auth">
              <Link to="/watch" className={!!isMtnUser && "mtn"}>
                Stream Now
              </Link>
            </p>
          ) : (
            <>
              {loading ? (
                <LoadingOutlined />
              ) : (
                <>
                  {!!user ? (
                    <p className="auth" onClick={logout}>
                      Logout
                    </p>
                  ) : (
                    <p className="auth">
                      <Link to="/stream-passes">Buy Stream Pass</Link>
                      &nbsp;/&nbsp;
                      <Link to="/login">Login</Link>
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </FlexibleDiv>
      )}
    </FooterWrap>
  );
};

export default Footer;
