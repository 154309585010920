import { instance } from "./axios";
import axios from "axios";

export const checkUserBalance = async () => {
  const { data } = await instance.get("/users/me");
  return data;
};

export const signupUser = async (payload) => {
  const { data } = await instance.post("/users/create", payload);
  return data;
};

export const createMtnUser = async (payload) => {
  const { data } = await instance.post("/users/mtn", payload);
  return data;
};
export const paystactPayments = async (payload) => {
  const { data } = await instance.post("/subscription/paystack", payload);
  return data;
};

export const loginUser = async (payload) => {
  const { data } = await instance.post("/auth/login", payload);
  return data;
};

export const subscribeNewsletter = async (payload) => {
  const { data } = await instance.post("/users/newsletter", payload);
  return data;
};

export const getEnrichment = async (payload) => {
  const { data } = await instance.get("/users/mtn", payload);
  // const { data } = await axios.get('http://he.udux.com/');
  return data;
};
export const makeItRain = async (amount) => {
  const { data } = await instance.post("/events/buy", {
    amount,
    eventId: "0COwQCRHoDCqIVr7fSLN",
    itemType: "make-it-rain",
    pinnedComment: "I am the king money",
  });

  return data;
};
export const getCurrentUser = async () => {
  const { data } = await instance.get("/users/me");
  return data;
};

export const forgotPassword = async function (email) {
  const { data } = await instance.post("/auth/forgot-password", email);

  return data;
};

export const resetPassword = async function (passwordResetToken, newPassword) {
  const { data } = await instance.post(
    "/auth/reset-password?passwordResetToken=" + passwordResetToken,
    newPassword
  );

  return data;
};

export const addPopRev = async function (payload) {
  const { data } = await instance.post("/users/poprev", payload);

  return data;
};

export const stripeClientSecret = async function (payload) {
  const { data } = await instance.post(
    "/subscription/stripe/create-payment-intent",
    payload
  );

  return data;
};

export const deductBalance = async function (payload) {
  const { data } = await instance.post("/subscription/deduct", payload);

  return data;
};
