import styled from "styled-components";

export const ArenaLiveSection = styled.section`
  height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
	@media (max-width: 800px) {
		padding-top: 50px;
	}
`;

export const ArenaLiveWrapper = styled.div`
  width: 100%;
  height: max-content;
  padding: 0 5% 10px 5%;
  position: relative;
  box-sizing: border-box;

  .davidoText {
    position: absolute;
    bottom: 3.5%;
    left: 10%;
    justify-content: flex-start;
    h1 {
      color: #fff;
      font-weight: bold;
      font-size: 180px;
      margin-bottom: 0;
    }
    @media (max-width: 1000px) {
      h1 {
        font-size: 140px;
      }
      bottom: -90px;
    }
  }
  .rightSection {
    margin-top: 150px;
  }

  .imageBox {

    h2 {
      color: #fff;
      font-weight: bold;
      font-size: 80px;
      margin: 0;
      @media (min-width: 800px) {
        display: none;
      }
    }
    p {
      font-size: 30px;
      margin: 0;
      color: #fcb316;
      .spanText {
        font-size: 14px;
      }
    }
    .spanText {
      color: #fff;
      max-width: 35%;
      margin-top: 20px;
      min-width: 250px;
    }
    video {
      width: 100%;
      height: auto;
      max-width: 400px;
    }
    .rightSectionImagwWrap {
      width: 90%;
      height: auto;
      max-width: 380px;
      margin-right: 5%;
      video {
        width: 100%;
      }
    }
  }

  @media (max-width: 800px) {
    padding: 0 0 100px 0;
    min-height: 80vh;

    .davidoText {
      display: none;
    }

    .imageBox {
      flex-direction: column;
      align-items: flex-end;
      & > div {
        justify-content: flex-end;
        width: 100%;
        height: 60%;

        video {
          width: 80%;
          max-width: 500px;
        }
      }
      span {
        max-width: 85%;
      }
      .rightSection {
        position: absolute;
        bottom: 70px;
        left: 5%;
        .rightSectionImagwWrap {
          display: none;
        }

        p {
          font-size: 20px;
          max-width: 90%;
        }

        h2 {
          line-height: 90px;
          font-size: 60px;
        }
      }
    }
  }

  @media (min-width: 800px) and (max-height: 700px) {
    padding: 0 0 80px 0;
  }
  @media (max-width: 800px) and (max-height: 840px) {
    min-height: 100vh;
    padding: 0 0 170px 0;
  }
`;
