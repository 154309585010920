import React, { useLayoutEffect, useState, useContext } from "react";
import { FlexibleDiv } from "../../components/Box/styles";
import LandingLayout from "../../components/Layout";
import { StreamPassWrapper } from "./styles";
import { streamPassData, continents } from "../../utils/dataHelper";
import Arrow from "../../assets/svgs/arrow.svg";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { usePosition } from "use-position";
import axios from "axios";
import { MainContext } from "../../context/MainContext";
import { Spin } from "antd";

const StreamPass = () => {
  const [passData, setPassData] = useState();
  const [pageState, setPagestate] = useState();
  const [pageLoading, setPageloading] = useState();
  const [activePass, setActivePassData] = useState();
  const { latitude, longitude, error } = usePosition();
  const {
    state: { user },
  } = useContext(MainContext);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!latitude || !longitude) return;
    async function fetchData() {
      setPageloading(true);
      try {
        const { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_GEOCODE_API}&sensor=true`
        );

        var n = data?.plus_code?.compound_code.split(" ");
        var newStr = n[n.length - 1];
        let currentContinent;

        continents.map((contData) => {
          if (
            contData.countries.includes(newStr) ||
            contData.codes.includes(newStr)
          ) {
            currentContinent = contData.continent;
          }

          return currentContinent;
        });

        if (currentContinent === "Africa") {
          setPassData(streamPassData[0]);
          setActivePassData({ ...streamPassData[0].list[0] });
        } else {
          setPassData(streamPassData[1]);
          setActivePassData({ ...streamPassData[1].list[0] });
        }

        setPagestate(true);
        setPageloading(false);
      } catch (e) {
        setPagestate(false);
        setPageloading(false);
      }
    }
    fetchData();

    return () => {
      setPagestate();
    };
  }, [latitude, longitude]);

  const handleRoute = (value) => {
    if (!!user?._id) {
      localStorage.setItem("user_object", JSON.stringify(user));
      localStorage.setItem("active_plan", JSON.stringify(activePass));
      navigate("/payments");
    } else {
      localStorage.setItem("active_plan", JSON.stringify(activePass));
      navigate("/signup");
    }
  };

  return (
    <LandingLayout>
      <StreamPassWrapper>
        {pageLoading ? (
          <FlexibleDiv height="60vh">
            <Spin size="large" />
          </FlexibleDiv>
        ) : (
          <>
            {!!pageState ? (
              <FlexibleDiv height="100%">
                <FlexibleDiv width="80%" flexDir="column">
                  <FlexibleDiv
                    height="100%"
                    justifyContent="space-between"
                    className="bodyWrap"
                    flexWrap="nowrap"
                  >
                    <FlexibleDiv
                      width="40%"
                      className="left_section"
                      flexWrap="nowrap"
                    >
                      <FlexibleDiv
                        className="passCard"
                        flexDir="column"
                        justifyContent="space-between"
                        flexWrap="nowrap"
                      >
                        <Typography.Title level={2}>
                          {activePass?.plan}
                        </Typography.Title>

                        <FlexibleDiv className="cardimageWrap">
                          <img src={activePass?.image} alt="" />
                        </FlexibleDiv>
                        <FlexibleDiv
                          flexDir="column"
                          className="contentWrap"
                          justifyContent="flex-start"
                        >
                          <FlexibleDiv flexDir="column">
                            {activePass?.currency === "NGN" ? (
                              <Typography.Title level={3}>
                                &#8358;{activePass?.price}
                              </Typography.Title>
                            ) : (
                              <Typography.Title level={3}>
                                {activePass?.sign + activePass?.price}
                              </Typography.Title>
                            )}
                            {activePass?.specs.map((item, idx) => (
                              <FlexibleDiv flexWrap="nowrap" key={idx}>
                                <img src={Arrow} alt="" />
                                <span>{item}</span>
                              </FlexibleDiv>
                            ))}
                          </FlexibleDiv>

                          <Typography.Title level={2} onClick={handleRoute}>
                            Buy Now
                          </Typography.Title>
                        </FlexibleDiv>
                      </FlexibleDiv>
                    </FlexibleDiv>
                    <FlexibleDiv
                      className="numbers"
                      flexDir="column"
                      width="max-content"
                    >
                      {passData?.list.map((item, idx) => (
                        <p
                          key={idx}
                          onClick={() => setActivePassData(item)}
                          className={
                            item.plan === activePass?.plan
                              ? "active"
                              : undefined
                          }
                        >
                          {idx + 1}
                        </p>
                      ))}
                    </FlexibleDiv>

                    <FlexibleDiv
                      className="right_section"
                      flexDir="column"
                      width="40%"
                      flexWrap="nowrap"
                      alignItems="flex-start"
                    >
                      <Typography.Title>
                        Stream <br />
                        <span>Passes</span>
                      </Typography.Title>
                      <span>
                        The WRBLO stream is available to stream to viewers all
                        over the world. We’ve made it super easy - simply
                        purchase a stream pass and login to your account at 8pm
                        GMT on March 5th 2022. <br /> <br />
                        <span>
                          The Livestream is free for MTN Nigeria subscribers
                          only.
                        </span>
                      </span>
                      <FlexibleDiv className="rightimagesWrap">
                        {passData?.list.map(
                          (plans, idx) =>
                            plans.plan !== activePass?.plan && (
                              <FlexibleDiv
                                key={idx}
                                width="47%"
                                onClick={() => setActivePassData(plans)}
                              >
                                <img src={plans.image} alt="" />
                              </FlexibleDiv>
                            )
                        )}
                      </FlexibleDiv>
                    </FlexibleDiv>
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
            ) : (
              <FlexibleDiv height="60vh">
                <FlexibleDiv className="pageState">
                  <Typography.Title level={4}>
                    This app needs your location to continue. Please reset this
                    in your browser settings if blocked in order to proceed,
                  </Typography.Title>
                </FlexibleDiv>
              </FlexibleDiv>
            )}
          </>
        )}
      </StreamPassWrapper>
    </LandingLayout>
  );
};

export default StreamPass;
