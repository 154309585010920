import React, { useEffect, useState, useContext } from "react";
import { LivestreamWrap, PopOverWrap } from "./styles";
import { GoPrimitiveDot as Dot } from "react-icons/go";
import DollarBill from "../../assets/pngs/davido-dollar.png";
import { usePaystackPayment } from "react-paystack";
import { formatMoney } from "../../utils/functionHelpers";
import LandingLayout from "../../components/Layout";
import { FlexibleDiv } from "../../components/Box/styles";
import { useNavigate } from "react-router-dom";
import { Popover, Spin, Form, notification } from "antd";
import moment from "moment";
import { AUDIO_PLAY } from "../../context/types";
import { MainContext } from "../../context/MainContext";
import Button from "../../components/Button";
import Input from "../../components/TextField";
import {
  getCurrentUser,
  createMtnUser,
  deductBalance,
} from "../../network/user";
import { CURRENT_USER } from "../../context/types";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "./stripeCheckout";
import { SocketContext } from "../../socket";
import Logo from "../../assets/svgs/poprev-logo.svg";
import sprayMoney from "../../animations/spray_money";
import useStateRef from "../../hooks/useStateRef";
import { useQuery } from "react-query";
import { videoList } from "../../utils/dataHelper";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const LiveStream = () => {
  const navigate = useNavigate();
  const {
    dispatch,
    state: { user },
  } = useContext(MainContext);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [topupValue, setTopupValue] = useState();
  const [mtnUser, setMtnUser] = useState(false);
  const [showUserForm, setShowUserform] = useState(false);
  const [mtnUserLoading, setMtnUserLoading] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [popVisible, setPopVisible] = useState(false);
  const { socket } = useContext(SocketContext);
  const [sprayData, setSprayData] = useState();
  const [highestSpray, setHighestSpray] = useState();
  const [form] = Form.useForm();
  const { ref: isDrawing, setStateRef: setIsDrawing } = useStateRef(false);
  const { data: userData } = useQuery(
    "user",
    async () => await getCurrentUser()
  );
  const [currentVideo, setCurrentVideo] = useState();

  useEffect(() => {
    dispatch({ type: CURRENT_USER, payload: userData });
  }, [userData]);

  useEffect(() => {
    let video = localStorage.getItem("video");
    if (!!video) {
      setCurrentVideo(video);
    } else {
      setCurrentVideo(
        "https://player.vimeo.com/video/689657872?h=6aa99d88af&color=f3b12d&byline=0&portrait=0"
      );
    }
  }, [userData]);

  useEffect(() => {
    socket.on("spray_event", (data) => {
      setSprayData(data);
      sprayMoney(data?.amount, isDrawing, setIsDrawing);
    });

    return function cleanup() {
      socket.off("spray_event");
    };
  }, []);

  useEffect(() => {
    socket.on("spender_event", (data) => {
      setHighestSpray(data);
    });

    return function cleanup() {
      socket.off("spender_event");
    };
  }, []);

  useEffect(() => {
    const isMtnUser = localStorage.getItem("isMtn_user");
    setMtnUser(isMtnUser);

    const fetchUser = async () => {
      setPageLoading(true);
      try {
        const data = await getCurrentUser();
        dispatch({
          type: AUDIO_PLAY,
          payload: false,
        });
        dispatch({
          type: CURRENT_USER,
          payload: data,
        });
        setPageLoading(false);
      } catch (e) {
        setPageLoading(false);
        if (isMtnUser === "") {
          navigate("/login");
        }
      }
    };
    fetchUser();

    return function cleanup() {
      fetchUser();
    };
  }, []);

  const momentObj = moment("2022-03-05T20:00:00");

  const handleTopupChange = (e, currency) => {
    const { value } = e.target;
    if (value === "") {
      setTopupValue();
      return;
    }
    setTopupValue({
      amount: value,
      currency: currency,
    });
  };

  const getMtnUserData = () => {
    if (!!mtnUser && !user) {
      setShowUserform(true);
    } else {
      setShowAdd(true);
    }
  };

  async function handleFormSubmit(values) {
    setMtnUserLoading(true);
    const mtnPayload = {
      ...values,
    };
    try {
      const data = await createMtnUser(mtnPayload);
      dispatch({ type: CURRENT_USER, payload: { ...data?.user } });
      localStorage.setItem("user_token", data?.token);
      setMtnUserLoading(false);
      setShowUserform(false);
      setShowAdd(true);
    } catch (e) {
      console.log(e);
      setMtnUserLoading(false);
    }
  }
  const getData = async () => {
    try {
      const data = await getCurrentUser();

      dispatch({ type: CURRENT_USER, payload: data });
      setShowAdd(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onSuccess = (reference) => {
    getData();
  };

  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const payStackPayload = {
    reference: new Date().getTime().toString(),
    email: user?.email,
    amount: topupValue?.amount + "00",
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    metadata: {
      userId: user?._id,
      event_type: "wallet",
    },
  };

  const initializePayment = usePaystackPayment(payStackPayload);

  function handleShowPaymentForm() {
    // const amount = parseInt(topupValue?.amount);
    // if (topupValue?.currency === "NGN" && amount < 1000) {
    //   notification.open({
    //     message: "Error",
    //     description: "amount cannot be less than 1000 naira",
    //     icon: <SmileOutlined style={{ color: "red" }} />,
    //   });
    //   return;
    // }
    // if (topupValue?.currency === "USD" && amount < 2) {
    //   notification.open({
    //     message: "Error",
    //     description: "amount cannot be less than 2 dollars",
    //     icon: <SmileOutlined style={{ color: "red" }} />,
    //   });
    //   return;
    // }
    if (topupValue?.currency === "NGN") {
      try {
        initializePayment(onSuccess, onClose);
      } catch (e) {
        console.log(e);
      }
    } else {
      setShowAdd(false);
      setShowPaymentForm(true);
    }
  }

  const closeAll = () => {
    setShowPaymentForm(false);
    setShowAdd(false);
    setShowUserform(false);
    setTopupValue();
  };

  const handleDeduct = async (values) => {
    // const amount = parseInt(values?.amount);
    // if (amount < 1000) {
    //   notification.open({
    //     message: "Error",
    //     description: "amount cannot be less than 1000 naira",
    //     icon: <SmileOutlined style={{ color: "red" }} />,
    //   });
    //   return;
    // }

    setPaymentLoading(true);
    const payload = {
      ...values,
    };

    try {
      await deductBalance(payload);
      const data = await getCurrentUser();
      dispatch({ type: CURRENT_USER, payload: data });
      setPaymentLoading(false);
      setPopVisible(!popVisible);
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.error,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setPaymentLoading(false);
        console.log(error);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setPaymentLoading(false);
      }
    }
  };

  const setVideo = (video) => {
    localStorage.setItem(video, video);
    setCurrentVideo(video);
  };

  const topup = (
    <PopOverWrap id="popover-wrap">
      {!!userData?.wallet?.walletBalance > 0 ||
      !!user?.wallet?.walletBalance > 0 ? (
        <FlexibleDiv className="pop-content-wrap">
          <FlexibleDiv>
            <span>Show OBO the Money</span>
          </FlexibleDiv>

          <small className="balanceHeader">Your Available Balance</small>
          <h1 className="balance">
            N{" "}
            {formatMoney(
              userData?.wallet?.walletBalance || user?.wallet?.walletBalance
            )}
          </h1>
          <Form form={form} onFinish={handleDeduct}>
            <small className="sprayText">I want to Spray</small>
            <Form.Item
              name="amount"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input placeholder="0.00" width="100%" type="number" />
            </Form.Item>
            <Button
              variant="warning"
              className="text-white "
              width="100%"
              htmlType="submit"
              loading={isPaymentLoading}
            >
              Spray Money
            </Button>
          </Form>
        </FlexibleDiv>
      ) : (
        <>
          {!!showAdd ? (
            <FlexibleDiv className="pop-content-wrap">
              <span>I Want To Topup My Wallet Balance</span>
              <FlexibleDiv justifyContent="space-between">
                <FlexibleDiv
                  width="47%"
                  flexDir="column"
                  margin="20px 0 40px  0"
                >
                  <h5>Stripe ($)</h5>
                  <Input
                    placeholder="0.00"
                    type="number"
                    onChange={(e) => handleTopupChange(e, "USD")}
                    disabled={topupValue?.currency === "NGN" && true}
                  />
                </FlexibleDiv>
                <FlexibleDiv
                  width="47%"
                  flexDir="column"
                  margin="20px 0 40px 0"
                >
                  <h5>Paystack (&#8358;)</h5>
                  <Input
                    placeholder="0.00"
                    type="number"
                    onChange={(e) => handleTopupChange(e, "NGN")}
                    disabled={topupValue?.currency === "USD" && true}
                    width="100%"
                  />
                </FlexibleDiv>
              </FlexibleDiv>

              <Button
                variant="warning"
                className="text-white"
                disabled={!topupValue?.amount && true}
                onClick={handleShowPaymentForm}
                width="100%"
              >
                Top Up
              </Button>
            </FlexibleDiv>
          ) : topupValue?.currency === "USD" && !!showPaymentForm ? (
            <FlexibleDiv className="pop-content-wrap">
              <span>Stripe</span>
              <Elements stripe={stripe}>
                <StripeCheckout
                  user={user}
                  topupValue={topupValue}
                  dispatch={dispatch}
                  setShowPaymentForm={setShowPaymentForm}
                />
              </Elements>
            </FlexibleDiv>
          ) : !!showUserForm ? (
            <FlexibleDiv className="pop-content-wrap">
              <span>Add Your Details</span>
              <Form form={form} onFinish={handleFormSubmit}>
                <FlexibleDiv flexDir="column">
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" width="100%" />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" width="100%" />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input placeholder="EMAIL@udux.com" width="100%" />
                  </Form.Item>

                  <FlexibleDiv margin="10px 0">
                    <Button
                      width="100%"
                      height="50px"
                      htmlType="submit"
                      loading={mtnUserLoading}
                    >
                      Submit
                    </Button>
                  </FlexibleDiv>
                </FlexibleDiv>
              </Form>
            </FlexibleDiv>
          ) : (
            <FlexibleDiv className="pop-content-wrap">
              <span>Top up you so you can spray OBO during the concert</span>
              <small>Your Available Balance</small>
              <br /> <br />
              <h1>0.00</h1>
              <Button onClick={getMtnUserData} width="100%">
                Fund Wallet
              </Button>
            </FlexibleDiv>
          )}
        </>
      )}
    </PopOverWrap>
  );

  return (
    <LandingLayout>
      <LivestreamWrap>
        {pageLoading ? (
          <FlexibleDiv height="80vh">
            <Spin size="large" />
          </FlexibleDiv>
        ) : (
          <>
            {momentObj.isBefore() ? (
              <FlexibleDiv
                flexDir="column"
                height="100%"
                justifyContent="flex-start"
                flexWrap="nowrap"
                alignItems="flex-start"
              >
                <FlexibleDiv justifyContent="space-between" className="header">
                  <FlexibleDiv justifyContent="flex-start">
                    <img src={Logo} alt="" />
                    <h4>
                      Make $$$ by Streaming Music You’re Invested in.{" "}
                      <a target="_blank" href="https://wearepoprev.com/">
                        Learn More
                      </a>
                    </h4>
                  </FlexibleDiv>
                </FlexibleDiv>
                <FlexibleDiv
                  className="body_wrap"
                  height="100%"
                  justifyContent="space-between"
                >
                  <FlexibleDiv width="65%" className="video_main_wrap">
                    <FlexibleDiv className="video__section">
                      <FlexibleDiv
                        className="header_wrap"
                        justifyContent="space-between"
                      >
                        <span>
                          <Dot className="dot" />
                          W.R.B.L.O
                        </span>

                        {/* <Popover
                          trigger="click"
                          placement="bottomRight"
                          content={topup}
                          onClick={closeAll}
                          visible={popVisible}
                          onVisibleChange={() => setPopVisible(!popVisible)}
                        >
                          <span className="sprayText">Spray OBO</span>
                          <img
                            src={DollarBill}
                            alt="dollar bill"
                            className="border border-warning"
                            // onClick={() => {
                            //   Add(false);
                            // }}
                          />
                        </Popover> */}
                      </FlexibleDiv>

                      <iframe
                        title="live stream"
                        src={currentVideo}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        className="video"
                        id="iframeId"
                      ></iframe>
                    </FlexibleDiv>
                  </FlexibleDiv>

                  <FlexibleDiv className="chat__section" height="100%">
                    <FlexibleDiv
                      flexWrap="nowrap"
                      className="highestSpray"
                      justifyContent="flex-start"
                      width="95%"
                    >
                      <h3>Up Next</h3>
                      {/* <h3>The Baddest</h3>
                      {!!highestSpray && (
                        <FlexibleDiv
                          flexDir="column"
                          width="max-content"
                          alignItems="flex-end"
                        >
                          <h4>
                            {highestSpray?.firstName} {highestSpray?.lastName}
                          </h4>
                          <h5>&#8358;{formatMoney(highestSpray?.amount)}</h5>
                        </FlexibleDiv>
                      )} */}
                    </FlexibleDiv>
                    <FlexibleDiv
                      className="chat_main"
                      flexDir="column"
                      justifyContent="flex-start"
                    >
                      {/* <FlexibleDiv
                        className="chat_header"
                        flexWrap="nowrap"
                        justifyContent="flex-start"
                      >
                        <img src={DollarBill} alt="" />
                        <FlexibleDiv
                          flexDir="column"
                          alignItems="flex-start"
                          flexWrap="nowrap"
                        >
                          <h4>The Queue</h4>
                          <FlexibleDiv
                            flexWrap="nowrap"
                            justifyContent="space-between"
                          >
                            <h5 className="makeItRain">Make it Rain</h5>
                            <h5 className="name">
                              {sprayData?.firstName} &nbsp;
                              {sprayData?.lastName}
                            </h5>

                            <h5 className="amount">
                              &#8358;{formatMoney(sprayData?.amount) || 0}
                            </h5>
                          </FlexibleDiv>
                        </FlexibleDiv>
                      </FlexibleDiv> */}
                      <FlexibleDiv className="chat_body">
                        {videoList.map((items, idx) => (
                          <FlexibleDiv
                            className={
                              items.videoUrl === currentVideo
                                ? "videoItem playing"
                                : "videoItem"
                            }
                            justifyContent="flex-start"
                            onClick={() => {
                              setVideo(items.videoUrl);
                            }}
                            key={idx}
                            flexWrap="nowrap"
                          >
                            <div className="imageWrap">
                              <img alt="" src={items.thumbnail} />
                            </div>
                            <FlexibleDiv
                              className="text__wrap"
                              alignItems="flex-start"
                              width="80%"
                              flexDir="column"
                            >
                              <span>Episode {items.Episode}</span>
                              <span className="white">{items.videoTitle}</span>
                            </FlexibleDiv>
                          </FlexibleDiv>
                        ))}
                        {/* <iframe
                          src="https://vimeo.com/event/1913832/chat/"
                          width="100%"
                          height="100%"
                          frameborder="0"
                        ></iframe> */}
                      </FlexibleDiv>
                    </FlexibleDiv>
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
            ) : (
              <FlexibleDiv height="90%" className="countDown">
                <iframe
                  title="live stream"
                  src="https://player.vimeo.com/video/680770828?h=e7003a4f8c&color=f3b12d&byline=0&portrait=0"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{ width: "100%", height: "500px" }}
                  className="video"
                  id="iframeId"
                ></iframe>
              </FlexibleDiv>
            )}
          </>
        )}
      </LivestreamWrap>
      <canvas></canvas>
    </LandingLayout>
  );
};

export default LiveStream;
