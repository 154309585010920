import splitting from "splitting";
import React, { useEffect } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { DavidoNeedsWrapper } from "./styles";

const DavidoNeeds = ({ isMtnUser }) => {

  useEffect(() => {
		setTimeout(() => {
			splitting({
				by: "words",
				target: "#davido_needs [data-splitting]",
			});
		}, 0.5);
  }, [isMtnUser]);

  return (
    <DavidoNeedsWrapper id="davido_needs" className="home_section">
      <FlexibleDiv height="100%" flexWrap="nowrap">
        <FlexibleDiv
          justifyContent="flex-start"
          width="65%"
          className="left_section"
        >
          <img src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/davido-lineup.png" alt="" />
        </FlexibleDiv>
        <FlexibleDiv
          width="35%"
          flexDir="column"
          alignItems="flex-start"
          height="100%"
          justifyContent="space-between"
          className="right_section"
        >
          <FlexibleDiv
            height="40%"
            className="rightTop"
            justifyContent="flex-start"
          >
            <h1 data-splitting="">We Rise by Lifting Others</h1>
          </FlexibleDiv>
          <FlexibleDiv
            height="60%"
            className="rightBottom"
            flexDir="column"
            alignItems="flex-start"
            flexWrap="nowrap"
          >
            <p className="text" data-splitting="">05.03.2022</p>
            {!!isMtnUser ? (
              <div className="spanText">
                <span data-splitting="">To watch Davido perform live at London's O2 Arena "We Rise By
                Lifting Others" concert of the 5th of March 2022:</span>
                <br />
                <span data-splitting="">Simply visit the livestream website using the link</span>
                <a data-splitting="" href="/" target="_blank">
                  WRBLO2.UDUX.COM
                </a>
                <br /> <br />
                <span data-splitting="">Top up your data to avoid stories that touch. Follow us on
                social for backstage access and live updates.</span>
                <br />
                <FlexibleDiv justifyContent="space-between" width="160px">
                  <span data-splitting="">
                    Instagram
                    <br />
                    <a href="https://www.instagram.com/mtnng" target="blank">
                      @mtnng
                    </a>
                  </span>
                  <span data-splitting="">
                    Twitter
                    <br />
                    <a href="https://twitter.com/mtnng" target="blank">
                      @mtnng
                    </a>
                  </span>
                </FlexibleDiv>
              </div>
            ) : (
              <div className="spanText">
                <span data-splitting="">
									We’ve partnered with the Baddest himself to
									broadcast what is arguably one of the best
									lineups and setlists to ever grace the 02
									stage. We simply couldn’t leave all the
									vibes in London - we’re taking this all over
									the world.
								</span>
								<br /> <br />
								<span data-splitting="">Are you Ready?!</span>
								<br /> <br />
								<span data-splitting="">
									The livestream is free for all MTN Nigeria
									subscribers. For anyone else around the
									world - buy a stream pass and tune in from
									8pm GMT on March 5th 2022.
								</span>
							</div>
						)}
					</FlexibleDiv>
				</FlexibleDiv>
			</FlexibleDiv>
		</DavidoNeedsWrapper>
  );
};

export default DavidoNeeds;
