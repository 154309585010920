import gsap from "gsap";

export const davidoNeedsIn = () => {
	const TL = gsap.timeline();
  const davido_needs = gsap.utils.selector("#davido_needs");
	TL.set("#davido_needs", { visibility: "visible" })
  .set(davido_needs("[data-splitting]"), { visibility: "visible" })
  .to("#davido_needs", {
		delay: 0.75,
		opacity: 1,
		duration: 1,
		ease: "circ.out",
	})
  .from("#davido_needs img", {
    scale: 1.5,
    duration: 2,
    ease: "expo.out",
  }, 0.8)
  .from(davido_needs(".word, .whitespace"), {
    duration: 0.5,
    opacity: 0,
    yPercent: 75,
    stagger: 0.0075,
    ease: "power2.out",
  }, 1);
};

export const davidoNeedsOut = () => {
	const TL = gsap.timeline();
  const davido_needs = gsap.utils.selector("#davido_needs");
	TL.to("#davido_needs", {
		opacity: 0,
		duration: 0.75,
		ease: "circ.in",
	})
  .set("#davido_needs", { visibility: "hidden" })
  .set(davido_needs("[data-splitting]"), { visibility: "hidden" });
};
