import styled from "styled-components";

export const SideBarWrapper = styled.div`
  width: 60px;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  border-right: 1px solid #707070;

  .menuItems {
    transform: rotate(90deg);
    flex-wrap: nowrap;
    width: 80%;
    height: 85%;
    a {
      white-space: nowrap;
      color: white;
      font-weight: bold;
      font-size: 12px;
      word-spacing: 7px;
      padding: 5px 15px;
      width: max-content;
      margin: 0 5%;
      text-decoration: none;
      @media (max-height: 650px) {
        font-size: 10px;
      }
      &[data-glitch] {
        position: relative;
        &:before,
        &:after {
          content: attr(data-glitch);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.8;
          padding: 5px 15px;
        }
        &:after {
          color: #f0f;
          z-index: -2;
          animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
            reverse both;
        }
        &:before {
          color: #0ff;
          z-index: -1;
          animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
            both;
        }
      }
      @keyframes glitch {
        0% {
          transform: translate(0);
        }
        20% {
          transform: translate(-1.5px, 1.5px);
        }
        40% {
          transform: translate(-1.5px, -1.5px);
        }
        60% {
          transform: translate(1.5px, 1.5px);
        }
        80% {
          transform: translate(1.5px, -1.5px);
        }
        to {
          transform: translate(0);
        }
      }
    }

    #activeTab {
      background: #fcb316;
    }
  }

  .iconsWrap {
    svg {
      color: #ffffff88;
      font-size: 20px;
      margin: 8px 0;
    }
  }

  @media (max-width: 800px) {
    display: none;
  }
`;
