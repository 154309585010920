import styled from "styled-components";

export const DavidoNeedsWrapper = styled.section`
  width: 100%;
  height: 100%;
  background: #000000;

  .left_section {
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: cover;

      @media (min-height: 800px) {
        height: 100%;
      }
      @media (max-height: 650px) {
        height: 100%;
      }
    }
  }
  .right_section {
    min-width: 350px;
    & > div {
      padding: 0 10%;
    }
    .rightTop {
      background: #fcb316;
      h1 {
        font-size: 45px;
        color: #fff;
        line-height: 1.23;
      }
    }
    .rightBottom {
      background: #000000;
      .text {
        color: #fcb316;
        font-size: 24px;
      }
      .spanText {
        color: #fff;
        font-size: 13px;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 800px) {
    padding-top: 50px;
    & > div {
      flex-wrap: wrap;
      position: relative;
    }
    ::before {
      content: "";
      width: 60%;
      height: 100px;
      position: absolute;
      top: 50px;
      right: 0;
      background: #fcb316;
    }

    .left_section {
      width: 80%;
      height: 80%;
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      margin-left: auto;
      margin-top: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right_section {
      position: absolute;
      width: 100%;
      min-width: 90%;
      flex-wrap: nowrap;

      .rightTop {
        height: 20%;
        background: transparent;
        z-index: 2;
        padding: 0 5%;
        padding-top: 20px;
        h1 {
          font-size: 40px;
        }
      }
      .rightBottom {
        background: #00000088;
        padding: 0 5%;
        height: 80%;
        z-index: 1;
      }
    }
  }
`;
