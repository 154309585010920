import splitting from "splitting";
import React, { useEffect } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { ArenaLiveSection, ArenaLiveWrapper } from "./styles";

const ArenaLive = ({ isMtnUser }) => {

  useEffect(() => {
		splitting({ by: "words", target: "#arena_live [data-splitting]" });
  }, []);

  return (
    <ArenaLiveSection id="arena_live" className="home_section">
      <ArenaLiveWrapper>
        <FlexibleDiv height="100%">
          <FlexibleDiv
            className="imageBox"
            justifyContent="space-between"
            height="100%"
          >
            <FlexibleDiv
              height="100%"
              alignItems="flex-start"
              justifyContent="flex-end"
              width="35%"
              className="leftSection"
            >
							<video
								autoPlay
								loop
								muted="muted"
							>
								<source
									type="video/webm"
									src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/davido-glitch.webm"
								/>
								<source
									type="video/mp4"
									src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/davido-glitch.mp4"
								/>
								Your browser does not support HTML5 video tag.
							</video>
            </FlexibleDiv>
            <FlexibleDiv
              height="100%"
              alignItems="flex-start"
              width="60%"
              className="rightSection"
            >
              <FlexibleDiv flexDir="column" alignItems="flex-start">
                <h2 className="davidoTextMobile">DAVIDO</h2>
                <p>
                  <span data-splitting="" className="spanText">We Rise by lifting others</span>
                </p>
                <p data-splitting="">LIVE FROM the o2 ARENA</p>
                <FlexibleDiv
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  {!!isMtnUser ? (
                    <span data-splitting="" className="spanText">
                      30 Billion Gang, are you ready for the Baddest concert
                      ever? Stream Davido's "We RIse By Lifting Others" live
                      from the O2 Arena brought to you my udux x MTN. <br />{" "}
                      <br />
                      MTN has partenerd with uduX to bring you one of the most
                      awaited concerts of 2022, happening live at the O2 Arena
                      in London. The free livestream of this concert will be
                      exclusive for MTN subscribers.
                    </span>
                  ) : (
                    <span data-splitting="" className="spanText">
                      On the 5th of March 2022, uduX will be live streaming
                      Davido’s takeover of the 02 Arena in London to everyone
                      across the globe. <br /> <br />
                      In anticipation of his next album, ‘We Rise by Lifting
                      Others’… OBO is taking London by Storm with arguably one
                      of the most stellar lineups that any concert has ever
                      seen.
                    </span>
                  )}

                  <div className="rightSectionImagwWrap">
										<video
											autoPlay
											loop
											muted="muted"
										>
											<source
												type="video/webm"
												src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/davido-glitch.webm"
											/>
											<source
												type="video/mp4"
												src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/davido-glitch.mp4"
											/>
											Your browser does not support HTML5
											video tag.
										</video>
                  </div>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv className="davidoText">
          <h1>DAVIDO</h1>
        </FlexibleDiv>
      </ArenaLiveWrapper>
    </ArenaLiveSection>
  );
};

export default ArenaLive;
