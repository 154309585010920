import getRandomNumberFromRange from "../utils/functionHelpers/getRandomNumberFromRange";
import note from "../assets/pngs/davido-dollar.png";

const TO_RADIAN = Math.PI / 180;
const image = new Image();
image.src = note;

const sprayMoney = (amount, isDrawing, setIsDrawing) => {
	console.log("func start", isDrawing.current);
	if (
		amount === 0 ||
		isDrawing.current === true ||
		typeof amount !== "number"
	)
		return;

	setIsDrawing(true);
	const NUM_OF_PIECES = amount / 50;

	const canvas = document.querySelector("canvas");
	console.log(image);
	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;
	const context = canvas.getContext("2d");
	const pieces = [];
	let step = 0;
	for (let i = 0; i < NUM_OF_PIECES; i++) {
		pieces.push({
			w: 20,
			h: 10,
			x: getRandomNumberFromRange(20, canvas.width - 20),
			y: getRandomNumberFromRange(0, -500),
			vy: getRandomNumberFromRange(2.5, 3.5),
			ay: getRandomNumberFromRange(0, 0.05),
			degree: 0,
			vdegree: getRandomNumberFromRange(-5, 5),
			cyclevsy: getRandomNumberFromRange(0, 0.3),
			cyclex: getRandomNumberFromRange(0, 20),
			cyclevx: getRandomNumberFromRange(0, 0.2),
			scale: getRandomNumberFromRange(0.35, 2.5),
		});
	}

	let interval_id = setInterval(function () {
		context.clearRect(0, 0, canvas.width, canvas.height);
		if (pieces.length === 0) {
			clearInterval(interval_id);
			setIsDrawing(false);
			return;
		}
		pieces.forEach((piece) => {
			const scaley = Math.sin(step * piece.cyclevsy) * piece.scale;

			context.save();
			context.translate(
				piece.x + Math.sin(step * piece.cyclevx) * piece.cyclex,
				piece.y
			);
			context.scale(piece.scale, scaley);
			context.rotate(piece.degree * TO_RADIAN);
			context.drawImage(image, -40, 0, 80, 35);
			context.restore();

			piece.degree += piece.vdegree;
			piece.y += piece.vy;
			piece.vy += piece.ay;
		});
		// remove
		let piece;
		for (let i = pieces.length - 1; i >= 0; i--) {
			piece = pieces[i];
			if (piece.y > canvas.height) {
				pieces.splice(i, 1);
			}
		}
		step++;
	}, 1000 / 60);
};

export default sprayMoney;
