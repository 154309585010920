import styled from "styled-components";
import { Button } from "antd";

export default styled(Button)`
  width: ${({ width }) => width || "240px"};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height || "60px"};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border || "1px solid #fcb316"};
  background: ${({ background }) =>
    background === "outlined"
      ? "transparent"
      : background
      ? background
      : "#fcb316"};
  border-radius: ${({ radius }) => radius || "2px"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  opacity: ${({ opacity }) => opacity || "1"};

  @media (max-width: 350px) {
    height: ${({ height }) => height === "68px" && "50px"};
  }
  :focus,
  :active {
    background: ${({ background }) =>
      background === "outlined"
        ? "transparent"
        : background
        ? background
        : "#fcb316"};
    border: ${({ border }) => border || "1px solid #fcb316"};
  }

  :hover {
    background: ${({ background }) =>
      background === "outlined"
        ? "transparent"
        : background
        ? background
        : "#fcb316"};
    border: ${({ border }) => border || "1px solid #fcb316"};
  }

  span,
  a {
    color: ${({ color }) => color || "#ffffff"} !important;
    font-weight: 600;
    font-size: ${({ fontSize }) => fontSize || "14px"};
  }
`;
