import gsap from "gsap";

export const previous = (index, delay, infoRefs, imageRefs, titleRefs) => {
  const TL = gsap.timeline({ delay: delay });
    const title = gsap.utils.selector(titleRefs[index].current);
    const prevTitle = gsap.utils.selector(titleRefs[index + 1]?.current);
    TL.to(imageRefs[index + 1]?.current, {
      duration: 1.5,
      ease: "power2.out",
      clipPath: "inset(0% 0% 100% 0%)",
    })
    .to(imageRefs[index]?.current, {
      duration: 1.5,
      ease: "power2.out",
      clipPath: "inset(0% 0% 0% 0%)",
    }, 0)
    .to(infoRefs[index + 1]?.current,{
      opacity: 0,
      ease: "power2.inOut",
    }, 0)
    .to(infoRefs[index].current,{
      delay: 0.25,
      opacity: 1,
      duration: 1.25,
      ease: "power2.inOut",
    }, 0)
    .to(prevTitle(".word > .char, .whitespace"), {
      duration: 1,
      ease: "power2.inOut",
      yPercent: window.innerWidth > 800 ? 100 : 200,
      stagger: window.innerWidth > 800 ? 0.02 : 0.01,
    }, 0)
    .to(title(".word > .char, .whitespace"), {
      yPercent: 0,
      duration: 1,
      ease: "power2.inOut",
      stagger: window.innerWidth > 800 ? 0.02 : 0.01,
    }, 0);
};

export const next = (index, delay, infoRefs, imageRefs, titleRefs) => {
  const TL = gsap.timeline({ delay: delay });
    const title = gsap.utils.selector(titleRefs[index].current);
    const prevTitle = gsap.utils.selector(titleRefs[index - 1]?.current);
    TL.to(imageRefs[index].current, {
      duration: 1.5,
      ease: "power2.out",
      clipPath: "inset(0% 0% 0% 0%)",
    })
    .to(infoRefs[index - 1]?.current,{
      opacity: 0,
      ease: "power2.inOut",
    }, 0)
    .to(infoRefs[index].current,{
      delay: 0.25,
      opacity: 1,
      duration: 1.25,
      ease: "power2.inOut",
    }, 0)
    .to(prevTitle(".word > .char, .whitespace"), {
      duration: 1,
      ease: "power2.inOut",
      stagger: window.innerWidth > 800 ? 0.02 : 0.01,
      yPercent: window.innerWidth > 800 ? -100 : -200,
    }, 0)
    .to(title(".word > .char, .whitespace"), {
      yPercent: 0,
      duration: 1,
      ease: "power2.inOut",
      stagger: window.innerWidth > 800 ? 0.02 : 0.01,
    }, 0);
};