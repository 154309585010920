import styled from "styled-components";

export const HeroSectionWrapper = styled.section`
  width: 100%;
  height: 100%;
  background: url("https://wrblo-live-fe.s3.us-east-2.amazonaws.com/square-blocks.png");
  background-size: 100% 100%;
  background-position: center;

  .imageBox {
    width: 550px;
    height: 550px;
    min-width: 280px;
    border-radius: 100%;
    border: 1px solid #fcb316;
    position: relative;

    & > div {
      width: 70%;
      border: "hello";
      video {
        width: 100%;
        height: auto;
        min-width: 280px;
      }
    }
    p {
      position: absolute;
      color: #fff;
      font-size: 20px;
    }

    .top {
      top: 7%;
    }
    .left {
      left: -30%;
      bottom: 43%;
      transform: rotate(90deg);
    }
    .right {
      right: -30%;
      bottom: 43%;
      transform: rotate(90deg);
    }
    .bottom {
      bottom: 3%;
    }
  }

  @media (max-width: 800px) {
    background: #100f0f;
		padding-top: 50px;

    .imageBox {
      width: 100%;
      height: max-content;
      margin-top: 45px;
      border: none;

      & > div {
        width: 80%;
        height: 90%;
      }
      .left {
        left: -190px;
      }
      .right {
        right: -190px;
      }
      .top {
        top: -50px;
      }
      .bottom {
        bottom: -60px;
      }
    }
  }

  @media (max-width: 500px) {
    .imageBox {
      p {
        font-size: 16px;
      }

      .left {
        left: -150px;
      }
      .right {
        right: -150px;
      }
    }
  }

  @media (max-width: 390px) {
    .imageBox {
      p {
        font-size: 14px;
      }

      .left {
        left: -130px;
      }
      .right {
        right: -130px;
      }
    }
  }

  @media (max-width: 280px) {
    .imageBox {
      p {
        display: none;
      }
    }
  }
`;
