import styled from "styled-components";

export const FAQWrapper = styled.div`
  width: 100%;
  height: max-content;
  padding-bottom: 50px;

  .top_wrap {
    height: 100vh;
    .left_section {
      h1 {
        font-size: 70px;
        color: #fff;
        margin: 0;
        @media (max-width: 800px) {
          font-size: 50px;
        }
      }
      p {
        font-size: 24px;
        color: #fcb316;
      }
      span {
        color: #fff;
        margin-top: 10px;
      }
    }
    .right_section {
      img {
        width: 70%;
        transform: rotate(-10deg);
      }
    }
    @media (min-width: 800px) {
      .left_section {
        width: 50%;
        span {
          max-width: 400px;
        }
      }
      .right_section {
        width: 50%;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column-reverse;
      height: max-content;
      padding: 40px 0;
      .right_section {
        margin: 80px 0;
        img {
          width: 60%;
          min-width: 280px;
        }
      }
    }
  }

  .bottom_wrap {
    min-height: 100vh;
    min-height: 100vh;
    background: url("https://wrblo-live-fe.s3.us-east-2.amazonaws.com/square-blocks.png");
    background-size: 100% 100%;
    background-position: center;
    padding: 50px 0;

    h1 {
      color: #fcb316;
      font-size: 70px;
      margin: 50px 0;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 30px;
      }
    }
    .tabs {
      color: #fff;
      white-space: nowrap;
      p {
        margin: 0 7px;
        cursor: pointer;
        transition: all 0.1s ease;
      }
      @media (max-width: 560px) {
        font-size: 8px;
        p {
          font-size: 8px;
        }
      }
      @media (max-width: 408px) {
        margin-bottom: 11px;
      }
      .active {
        position: relative;
        ::before {
          content: "";
          width: 100%;
          height: 4px;
          position: absolute;
          left: 0;
          bottom: -5px;
          background: #fcb316;
        }
      }
    }

    .faq_contents {
      border: 1px solid #696969;

      @media (max-width: 800px) {
        width: 93%;
      }

      & > div {
        border-bottom: 1px solid #696969;
        height: 80px;
        overflow: hidden;
        cursor: pointer;
        p,
        span {
          width: 100%;
          color: #fff;
          padding: 30px 5%;
        }
        span {
          padding: 20px 5%;
        }
        p {
          height: 80px;
          margin: 0;
          @media (max-width: 700px) {
            padding: 20px 5%;
            height: 90px;
          }
          @media (max-width: 400px) {
            padding: 15px 5%;
          }
        }
      }
    }
    .show {
      height: max-content !important;
      p {
        background: #fcb316;
        color: #000000 !important;
      }
    }
  }
`;
