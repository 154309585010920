import React, { useState, useContext } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { Typography, Form, Select, Checkbox, notification } from "antd";
import Input from "../../../components/TextField";
import Button from "../../../components/Button";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { signupUser } from "../../../network/user";
import {
  validatePwd,
  validateEmail,
  validatePhone,
} from "../../../utils/functionHelpers";
import {
  SmileOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import Signup from "..";
import { MainContext } from "../../../context/MainContext";
import { CURRENT_USER } from "../../../context/types";
import { countries } from "../../../utils/dataHelper";

const UserDetails = () => {
  const [agreement, setAgreement] = useState(false);
  const [country, setCountry] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { dispatch } = useContext(MainContext);

  const handleAgreement = (e) => {
    setAgreement(e.target.checked);
  };

  const handleCountry = (value) => {
    setCountry(value);
  };

  async function handleFormSubmit(values) {
    if (agreement === false) {
      return;
    }

    setIsLoading(true);

    const payload = {
      ...values,
      email: values.email.toLowerCase(),
      region: country,
    };

    try {
      const data = await signupUser(payload);
      localStorage.setItem("user_object", JSON.stringify(data));
      setIsLoading(false);
      navigate("/payments");
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.message === 'Email already exists' ? 'This account has been created already, Please login' : error.response.data.message,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        if(error.response.data.message === 'Email already exists') {
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }
        setIsLoading(false);
        console.log(error);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      }
    }
  }

  return (
    <Signup>
      <Typography.Title>Create Account</Typography.Title>

      <Form form={form} onFinish={handleFormSubmit}>
        <FlexibleDiv
          flexDir="column"
          height="100%"
          alignItems="flex-start"
          flexWrap="nowrap"
        >
          <FlexibleDiv className="shortInputSection">
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input height="40px" placeholder="FIRST NAME" width="100%" />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input height="40px" placeholder="LAST NAME" width="100%" />
            </Form.Item>
          </FlexibleDiv>
          <FlexibleDiv className="longInputSection">
            <Form.Item
              name="email"
              rules={[{ validator: validateEmail, required: true }]}
            >
              <Input height="40px" placeholder="EMAIL" width="100%" />
            </Form.Item>
          </FlexibleDiv>
          <FlexibleDiv className="longInputSection">
            <Form.Item
              rules={[{ required: true }]}
              name="password"
              placeholder=""
            >
              <Input.Password
                height="40px"
                placeholder="Password"
                width="100%"
                className="passwordInput"
              />
            </Form.Item>
          </FlexibleDiv>

          <FlexibleDiv className="shortInputSection">
            <Form.Item>
              <Select
                placeholder="Country"
                showSearch
                listHeight={1000}
                onChange={handleCountry}
              >
                {countries.map((value, index) => (
                  <Select.Option value={value} key={index}>
                    <FlexibleDiv justifyContent="flex-start">
                      {value}
                    </FlexibleDiv>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="phoneNumber">
              <Input
                height="40px"
                placeholder="Phone number"
                type="number"
                width="100%"
                rules={[{ validator: validatePhone, required: true }]}
                // max="11"
              />
            </Form.Item>
          </FlexibleDiv>
          <FlexibleDiv>
            <Checkbox onChange={handleAgreement}>
              <span className="links">
                I Accept uduX streaming
                <a href="https://udux.com/toc.html" blank="_blank">
                  {" "}
                  Terms & Conditions
                </a>
              </span>
            </Checkbox>
          </FlexibleDiv>
          <FlexibleDiv>
            <Checkbox>
              <span className="links">
                I agree to be contacted by uduX and Streaming Partners
              </span>
            </Checkbox>
          </FlexibleDiv>
          <FlexibleDiv justifyContent="space-between" margin="10px 0">
            <p onClick={() => navigate("/stream-passes")} className="goback">
              Go Back
            </p>
            <Button
              width="180px"
              height="40px"
              htmlType="submit"
              loading={isLoading}
            >
              Next
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </Form>
    </Signup>
  );
};

export default UserDetails;
