import React, { useState } from "react";
import { PopRevWrap, ModalWrapper } from "./styles";
import { FlexibleDiv } from "../../components/Box/styles";
import LandingLayout from "../../components/Layout";
import Button from "../../components/Button";
import Logo from "../../assets/svgs/poprev-logo.svg";
import { Form, Select, Radio, notification } from "antd";
import Input from "../../components/TextField";
import { SmileOutlined } from "@ant-design/icons";
import { addPopRev } from "../../network/user";

const PopRev = () => {
  const [radioValue, setRadiovalue] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const crew = ["Gen-Z", " Millennial ", " Gen Y", " Gen X ", " Boomer "];
  const handleUser = (e) => {
    setRadiovalue(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAgeRange = (value) => {
    setAgeRange(value);
  };

  async function handleFormSubmit() {
    if (radioValue === "") {
      return;
    }
    setIsLoading(true);

    const payload = {
      ageRange: ageRange,
      email: email,
      investor: radioValue === "investor" ? true : false,
    };

    try {
      const data = await addPopRev(payload);
      setShowModal(true);
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.error,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
        console.log(error);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      }
    }
  }
  return (
    <LandingLayout>
      <PopRevWrap>
        <FlexibleDiv>
          <FlexibleDiv
            height="100%"
            flexDir="column"
            justifyContent="space-between"
            flexWrap="nowrap"
            alignItems="flex-start"
            width="90%"
            className="wrap"
          >
            <FlexibleDiv
              justifyContent="flex-start"
              width="90%"
              height="30px"
              className="logo"
            >
              <img src={Logo} alt="" />
            </FlexibleDiv>

            <FlexibleDiv width="70%" className="content-wrap">
              <FlexibleDiv
                flexDir="column"
                maxWidth="400px"
                alignItems="flex-start"
              >
                <span>
                  Imagine a world where the possibilities in music are endless.
                  Where musicians are in full control of their careers and never
                  have to sign perpetuity deals that challenge their artistic
                  freedom.
                  <br />
                  <br />
                  We’re building a movement where fans can $tan, not only for
                  the love of music, but even more with equity in music.
                  <br />
                  <br />
                  Imagine no further. This is PopRev and we believe…"
                </span>
                <span className="pay_you">Music Should Pay You!</span>
                <Form>
                  <FlexibleDiv
                    flexDir="column"
                    height="100%"
                    alignItems="flex-start"
                    flexWrap="nowrap"
                  >
                    <Form.Item rules={[{ required: "true" }]}>
                      <Input
                        height="40px"
                        placeholder="Email"
                        width="100%"
                        onChange={handleEmail}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Select
                        placeholder="Age Range"
                        listHeight={1000}
                        onChange={handleAgeRange}
                      >
                        {crew.map((value, index) => (
                          <Select.Option value={value} key={index}>
                            <FlexibleDiv justifyContent="flex-start">
                              {value}
                            </FlexibleDiv>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <FlexibleDiv
                      justifyContent="flex-start"
                      margin="0 0 30px 0"
                    >
                      <Radio.Group onChange={handleUser} value={radioValue}>
                        <Radio value={"Investor"}>
                          I'm a potential investor
                        </Radio>
                        <Radio value={"Musician/Artist"}>
                          I'm a musician/artist
                        </Radio>
                      </Radio.Group>
                    </FlexibleDiv>

                    <Button
                      background="outlined"
                      border="1px solid #fff"
                      height="50px"
                      opacity=".5"
                      onClick={handleFormSubmit}
                      loading={loading}
                    >
                      JOIN THE MOVEMENT
                    </Button>
                  </FlexibleDiv>
                </Form>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>

        <ModalWrapper
          title=""
          visible={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          footer={null}
          centered={true}
          // closable={false}
        >
          <FlexibleDiv flexDir="column">
            <span className="header">
              Welcome to the <span> #MVMNT!</span>
            </span>
            <span className="bodyText">
              Let’s show you what we’ve been upto, we promise you’ll love it
            </span>
            <Button
              height="40px"
              background="outlined"
              border="1px solid #707070"
              opacity=".5"
              onClick={() => setShowModal(false)}
            >
              <a target="_blank" href="https://wearepoprev.com/">
                Visit PopRev
              </a>
            </Button>
            <FlexibleDiv className="footer" width="80%">
              <span>Back to WRBLO Live Stream</span>
            </FlexibleDiv>
          </FlexibleDiv>
        </ModalWrapper>
      </PopRevWrap>
    </LandingLayout>
  );
};

export default PopRev;
