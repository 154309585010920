import splitting from "splitting";
import React, { useEffect, useState } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { NewsLetterWrapper } from "./styles";
import { notification } from "antd";
import Logo from "../../../assets/svgs/uduxLogo.svg";
import MTN from "../../../assets/svgs/mtn-black.svg";
import MTNYellow from "../../../assets/svgs/mtn-yellow.svg";
import PoprevWhite from "../../../assets/svgs/poprev-allwhite.svg";
import Poprev from "../../../assets/svgs/poprev-logo-black.svg";
import Input from "../../../components/TextField";
import Button from "../../../components/Button";
import { subscribeNewsletter } from "../../../network/user";
import { SmileOutlined } from "@ant-design/icons";

const NewsLetter = ({ isMtnUser }) => {

  useEffect(() => {
		splitting({ by: "words", target: "#news_letter [data-splitting]" });
  }, [isMtnUser]);

  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const [isLoading, setIsLoading] = useState(false);

  async function handleFormSubmit(values) {
    setIsLoading(true);

    const payload = {
      email: email,
    };

    try {
      const data = await subscribeNewsletter(payload);

      notification.open({
        message: "Success",
        description: "You have subscribed to our newsletter",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });

      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.message,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
        console.log(error);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      }
    }
  }

  return (
    <NewsLetterWrapper id="news_letter" className="home_section">
      <FlexibleDiv height="100%" alignItems="flex-start">
        <FlexibleDiv
          className="top_section"
          flexDir="column"
          alignItems="flex-start"
        >
          <h2 data-splitting="">Sign up to our Newsletter</h2>
          <FlexibleDiv justifyContent="space-between">
            <FlexibleDiv className="inputContainer" flexWrap="nowrap" width="max-content">
              <Input
                border="2px solid #000000"
                height="50px"
                background="#fff"
                onChange={handleChange}
              />
              <Button
                radius="0"
                background="#000"
                border="000"
                width="max-content"
                height="50px"
                loading={isLoading}
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </FlexibleDiv>
            <img src={Logo} alt="" />
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv
          alignItems="flex-start"
          justifyContent="space-between"
          className="bottom_section"
        >
          <FlexibleDiv
            height="100%"
            className="leftBottom"
            flexDir="column"
            alignItems="flex-start"
            maxWidth="900px"
          >
            <h1 data-splitting="">Africa to the world...</h1>
          </FlexibleDiv>
          <FlexibleDiv
            className="rightBottom"
            alignItems="flex-end"
            justifyContent="flex-end"
            maxWidth="90%"
          >
            {!!isMtnUser ? (
              <span data-splitting="">
                As an African brand, we believe in youth and the power of
                technology to connect with their energy and passions <br />
                <br />
                In collaboration with UduX, we demonstrate how technology brings
                us together, wherever we are in the world, to enjoy the music we
                love..
                <br />
                <br /> We will continue to create opportunities for everyone to
                experience the best of Africa's unique talents.
              </span>
            ) : (
              <span data-splitting="">
                Africa to the World is a movement pioneered by uduX - Africa’s
                first indigenous music platform. <br />
                <br />
                We are providing the express path to a global audience. From
                Africa to the world and back to Africa. We want you to share the
                global experience of our biggest African stars; so that you can
                dream it to reality!
                <br />
                <br /> We do this all with the belief that - One day you’ll be
                the master of your own stage
              </span>
            )}
          </FlexibleDiv>
          <FlexibleDiv
            flexDir="column"
            className="patners mobile"
            background={!isMtnUser ? "#fff" : "transparent"}
          >
            {/* <p>In Partnership With</p> */}
            <FlexibleDiv>
              <img src={MTN} alt="" className="mtn" />
              {!isMtnUser ? (
                <>
                  <img src={Poprev} alt="" />
                  <img src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/wema-logo.png" alt="" />
                </>
              ) : null}
            </FlexibleDiv>
          </FlexibleDiv>
          <FlexibleDiv flexDir="column" className="patners desktop">
            {/* <p>In Partnership With</p> */}
            <FlexibleDiv>
              <img src={MTNYellow} alt="" className="mtn" />
              {!isMtnUser ? (
                <>
                  <img src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/wema-logo.png" alt="" />
                  <img src={PoprevWhite} alt="" />
                </>
              ) : null}
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </NewsLetterWrapper>
  );
};

export default NewsLetter;
