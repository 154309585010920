import styled from "styled-components";

export const StreamPassWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url("https://wrblo-live-fe.s3.us-east-2.amazonaws.com/square-blocks.png");
  background-size: 100% 100%;
  background-position: center;
  padding: 50px 0 100px 0;

  @media (max-width: 950px) {
    padding: 100px 0 70px 0;

    & > div {
      & > div {
        width: 90%;
      }
    }
  }

  .numbers {
    p {
      color: #fff;
      font-weight: 300 !important;
      margin: 10px 0;
      cursor: pointer;
    }
    .active {
      color: #fcb316;
    }
  }
  .right_section {
    span {
      color: #fff;
      max-width: 400px;
      span {
        color: #fcb316;
      }
    }
    h1 {
      font-size: 70px;
      color: #fff;
      line-height: 60px;
      margin: 20px 0;
      @media (max-width: 950px) {
        font-size: 55px;
      }
      span {
        color: #fcb316;
      }
    }

    .rightimagesWrap {
      /* justify-content: space-between; */
      margin-top: 30px;
      position: relative;
      h5 {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        color: #fff;
      }
      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }

  .left_section {
    .passCard {
      border: 1px solid #fcb316;
      width: 450px;
      height: 460px;
      border-radius: 14px;
      background: #000000;
      overflow: hidden;

      h2,
      h3 {
        color: #fcb316;
        font-size: 24px;
        padding: 10px 0 0 0;
        cursor: pointer;
        text-align: center;
        width: 100%;
      }
      .contentWrap {
        background: #fcb316;
        flex-wrap: nowrap;
        h2 {
          color: #fff;
          text-align: center;
        }

        & > div {
          background: #000000;
          min-height: 180px;
          overflow-y: hidden;
          padding: 5px 3%;
          align-items: flex-start;
          border-radius: 0 0 10px 10px;

          & > div {
            /* min-height: 40px; */
            width: 90%;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 10px;
            img {
              width: 20px;
            }
            span {
              color: #fff;
              font-size: 12px;
              width: 90%;
            }
          }
        }
      }

      .cardimageWrap {
        height: 200px;
        overflow: hidden;
        border: 2px solid #fcb316;
        img {
          width: 105%;
          height: auto;
          object-fit: cover;
          margin-top: -150px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    min-height: 100vh;

    .bodyWrap {
      flex-direction: column-reverse;

      & > div {
        width: 100%;
        span {
          max-width: 100%;
        }
        h1 {
          font-size: 45px;
        }
      }
      .rightimagesWrap {
        display: none;
      }
      .numbers {
        flex-direction: row;
        margin: 20px 0;
        p {
          margin: 10px;
        }
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    background: transparent;
    border: 1px solid #707070;
    border-radius: 0;
    font-size: 14px;
    color: #050bac;
    box-shadow: none;
    min-width: 200px;
  }
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #fcb316 !important;
    /* font-family: "Avapore", sans-serif !important; */
    margin-top: 5px;
  }

  .ant-select-arrow {
    svg {
      color: #fcb31699 !important;
    }
  }
  .pageState {
    width: 90%;
    max-width: 500px;
    h4 {
      color: #fff;
      text-align: center;
      text-transform: capitalize;
    }
  }
`;
