import React, { useState, useEffect } from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { Typography, Progress, notification } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Paystack from "../../../assets/pngs/paystack.png";
import Stripe from "../../../assets/pngs/stripe.png";
import Alat from "../../../assets/pngs/alat.png";
import StripePayment from "./stripe";
import Button from "../../../components/Button";
import Signup from "..";
import { paystactPayments } from "../../../network/user";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { MainContext } from "../../../context/MainContext";

const Payments = () => {
  const navigate = useNavigate();
  const [activePayment, setActivePayment] = useState("");
  const [activePlan, setActivePlan] = useState();
  const [userObject, setuserObject] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const active_plan = await JSON.parse(localStorage.getItem("active_plan"));
      const userObj = await JSON.parse(localStorage.getItem("user_object"));
      setActivePlan(active_plan);
      setuserObject(userObj);
    };

    getData();
  }, []);

  async function handlePayStack() {
    setActivePayment("Paystack");
    setIsLoading(true);
    const active_plan = await JSON.parse(localStorage.getItem("active_plan"));
    const userObj = await JSON.parse(localStorage.getItem("user_object"));

    const payload = {
      amount: active_plan?.price,
      currency: active_plan?.currency,
      userId: userObj?._id,
    };

    try {
      const data = await paystactPayments(payload);

      if (data?.url) {
        window.location.href = data.url;
      } else {
        notification.open({
          message: "Congratulations",
          description: data,
          icon: <SmileOutlined style={{ color: "green" }} />,
        });
        navigate("/login");
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        notification.open({
          message: "Error",
          description: error.response.data.message,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      } else {
        notification.open({
          message: "Error",
          description: "Network error",
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setIsLoading(false);
      }
    }
  }
  return (
    <Signup>
      <FlexibleDiv>
        <Typography.Title>Choose Payment Method</Typography.Title>
        <FlexibleDiv flexWrap="nowrap" justifyContent="space-evenly">
          {activePlan?.currency !== "NGN" && (
            <FlexibleDiv
              onClick={() => setActivePayment("Stripe")}
              className={
                activePayment === "Stripe"
                  ? "payment-cards active-card"
                  : "payment-cards"
              }
              width="30%"
            >
              <img src={Stripe} alt="" />
            </FlexibleDiv>
          )}
          <FlexibleDiv
            onClick={handlePayStack}
            className={
              activePayment === "Paystack"
                ? "payment-cards active-card"
                : "payment-cards"
            }
            width="30%"
          >
            {isLoading && activePayment === "Paystack" ? (
              <LoadingOutlined variant={"red"} />
            ) : (
              <img src={Paystack} alt="" />
            )}
          </FlexibleDiv>
          {/* <FlexibleDiv
            onClick={() => setActivePayment("Alat")}
            className={
              activePayment === "Alat"
                ? "payment-cards active-card"
                : "payment-cards"
            }
            width="30%"
          >
            <img src={Alat} alt="" />
          </FlexibleDiv> */}
        </FlexibleDiv>
      </FlexibleDiv>

      {activePayment === "Stripe" && (
        <StripePayment
          activePlan={activePlan}
          userObject={userObject}
          setActivePayment={setActivePayment}
        />
      )}
    </Signup>
  );
};

export default Payments;
