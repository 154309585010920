import { createRef, useEffect, useState } from "react";

function useRefArray(length) {
	const [refs, setRefs] = useState([]);

	useEffect(() => {
		setRefs((currentRefs) =>
			Array(length)
				.fill(0)
				.map((_, i) => currentRefs[i] || createRef())
		);
	}, [length]);
	return refs;
}

export default useRefArray;
