import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { notification } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { stripeClientSecret } from "../../../network/user";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  useStripe,
  useElements,
  PaymentElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { FlexibleDiv } from "../../../components/Box/styles";

function CheckoutForm({ activePlan, userObject, setActivePayment, options }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: activePlan?.plan,
          amount: parseInt(activePlan?.price),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        console.log(result, "stripe");
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  // payments
  paymentRequest?.on("paymentmethod", async (ev) => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        options?.clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
      notification.open({
        message: "Error",
        description: "could not process payment",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: "https://wrblo2.udux.com/payment-confirmation",
          },
        });
        if (result.error) {
          // The payment failed -- ask your customer for a new payment method.
          notification.open({
            message: "Error",
            description: result.error.message,
            icon: <SmileOutlined style={{ color: "red" }} />,
          });
        } else {
          window.location.href = "https://wrblo2.udux.com/payment-confirmation";
        }
      } else {
        window.location.href = "https://wrblo2.udux.com/payment-confirmation";
      }
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setPaymentLoading(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://wrblo2.udux.com/payment-confirmation",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      notification.open({
        message: "Error",
        description: result.error.message,
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
      setPaymentLoading(false);
    } else {
      setPaymentLoading(false);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <FlexibleDiv className="stripe">
      <form onSubmit={handleSubmit}>
        <FlexibleDiv margin="0 0 20px 0" justifyContent="space-between">
          <h4>Pay With Stripe</h4>
          <CloseCircleFilled onClick={() => setActivePayment("")} />
        </FlexibleDiv>
        {!!paymentRequest && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
        {<PaymentElement />}
        {/* {showButton === null && ( */}
        <button className="pay-button" disabled={isPaymentLoading}>
          {isPaymentLoading ? "Loading..." : "Pay"}
        </button>
        {/* )} */}
      </form>
    </FlexibleDiv>
  );
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const StripePayment = ({ activePlan, userObject, setActivePayment }) => {
  const [secret, setSecret] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getStripeData = async () => {
      if (!stripe) {
        return;
      }
      try {
        const clientPayload = {
          amount: activePlan?.price,
          currency: "USD",
          plan: activePlan?.plan,
          // currency: activePlan?.currency,
          userId: userObject?._id,
        };
        const res = await stripeClientSecret(clientPayload);
        if(res.toLowercase().contains('congratulations')) {
          notification.open({
            message: "Congratulations",
            description: res,
            icon: <SmileOutlined style={{ color: "green" }} />,
          });
          navigate("/login");
        }
        setSecret(res?.clientSecret);
      } catch (error) {
        if (error.response) {
          notification.open({
            message: "Error",
            description: error.response.data.message,
            icon: <SmileOutlined style={{ color: "red" }} />,
          });
        } else {
          notification.open({
            message: "Error",
            description: "Network error",
            icon: <SmileOutlined style={{ color: "red" }} />,
          });
        }
      }
    };

    getStripeData();
    return;
  }, [stripe]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: secret,
  };
  return (
    !!secret && (
      <Elements stripe={stripe} options={options}>
        <CheckoutForm
          activePlan={activePlan}
          userObject={userObject}
          setActivePayment={setActivePayment}
          options={options}
        />
      </Elements>
    )
  );
};
export default StripePayment;
