import React, { useContext } from "react";
import { SideBarWrapper } from "./styles";
import { FlexibleDiv } from "../../Box/styles";
import { sideBarenuItems, mtnMenuItems } from "../../../utils/dataHelper";
import { Link, useLocation } from "react-router-dom";
import { FiInstagram as Insta, FiTwitter as Twitter } from "react-icons/fi";
import { MainContext } from "../../../context/MainContext";

const SideBar = ({ isMtnUser }) => {
  const location = useLocation();
  const {
    state: { user },
  } = useContext(MainContext);
  const active = location.pathname;

  return (
    <SideBarWrapper>
      <FlexibleDiv
        flexDir="column"
        justifyContent="flex-start"
        height="100%"
        flexWrap="nowrap"
      >
        <FlexibleDiv className="menuItems">
          {(!!isMtnUser || !!user ? mtnMenuItems : sideBarenuItems).map(
            (content, idx) => (
              <Link
                to={content.to}
                key={idx}
                data-glitch={content.item === "PopRev" ? "PopRev" : undefined}
                id={active === content.to ? "activeTab" : null}
                className={
                  !!isMtnUser && content.item === "Watch" ? "mtn" : null
                }
              >
                {content.item}
              </Link>
            )
          )}
        </FlexibleDiv>
        <FlexibleDiv
          className="iconsWrap"
          flexDir="column"
          height="60px"
          justifyContent="flex-start"
          flexWrap="nowrap"
        >
          <a href="https://www.instagram.com/uduxmusic/" target="_blank">
            <Insta />
          </a>
          <a href="https://twitter.com/udux_music" target="_blank">
            <Twitter />
          </a>
        </FlexibleDiv>
      </FlexibleDiv>
    </SideBarWrapper>
  );
};

export default SideBar;
