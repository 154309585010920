import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { HeroSectionWrapper } from "./styles";

const HeroSection = () => {
  return (
    <HeroSectionWrapper id="hero_section" className="home_section">
      <FlexibleDiv height="100%">
        <FlexibleDiv className="imageBox">
          <FlexibleDiv>
						<video
							autoPlay
							loop
							muted="muted"
						>
							<source
								type="video/webm"
								src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/hero-image.webm"
							/>
							<source
								type="video/mp4"
								src="https://wrblo-live-fe.s3.us-east-2.amazonaws.com/hero-image.mp4"
							/>
							Your browser does not support HTML5 video tag.
						</video>
          </FlexibleDiv>
          <p className="top">We Rise By Lifting Others</p>
          <p className="left">We Rise By Lifting Others</p>
          <p className="right">We Rise By Lifting Others</p>
          <p className="bottom">THE LIVESTREAM EVENT</p>
        </FlexibleDiv>
      </FlexibleDiv>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
