import gsap from "gsap";

export const heroSectionIn = () => {
  const TL = gsap.timeline();
  TL.to("#hero_section", {
    delay: 1.2,
    opacity: 1,
    duration: 1,
    ease: "circ.out",
  });
}

export const heroSectionOut = () => {
  const TL = gsap.timeline();
  TL.to("#hero_section", {
    opacity: 0,
    duration: 0.75,
    ease: "circ.in",
  });
}