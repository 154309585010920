import { notification } from "antd";
import moment from "moment";
// import { convert } from "html-to-text";

/**
 * this function takes two strings and returns a name initials
 * ***/
export const getNameInitials = (firstName = "", lastName = "") =>
  `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

/**
 * this function takes two strings and returns a name initials
 * ***/
export const getFullNameInitials = (fullName) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = [...fullName.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
  return initials;
};
/**
 *  This function truncates long texts and adds ellipses at the end
 * */
export const truncate = (str = "", length = 20, ending = "...") =>
  str.length > length
    ? `${str.substring(0, length - ending.length)} ${ending}`
    : str;

/**
 * formatDate
 * Returns a moment formatted date
 * @param {Date} date
 * */
export const formatDate = (date) => {
  let days = moment(date).fromNow();

  return days !== 0 ? days : moment(date).format("hh:mm  A");
};

export const urlify = (text) => {
  if (!text) return;
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return (
      '<a target="_blank" rel="noreferrer" href="' + url + '">' + url + "</a>"
    );
  });
};

export const boldify = (text) => {
  return text?.replace(/\*([^*]+?)\*/g, "<b>$1</b>");
};

export const convertToItalic = (text) => {
  return text?.replace(/_([^*]+?)_/g, "<em>$1</em>");
};

export const generateYoutubeLink = (url) => {
  const fullUrl = url.split("/");
  const videoLink = fullUrl[fullUrl.length - 1];
  return `https://youtube.com/embed/${videoLink}`;
};

export const is24hoursAgo = (date) => {
  return moment().subtract(24, "hours") > moment(date);
};

export const formatMoney = (amount) => {
  if (!amount) return;

  return parseInt(amount)
    .toFixed(0)
    .replace(/\d(?=(\d{3})+)/g, "$&,");
};

export function handleError(error) {
  if (error.response) {
    if (error.response.data.message) {
      let errorMessage = "";

      if (Array.isArray(error.response.data.message)) {
        error.response.data.message.map(
          (errorMsg) => (errorMessage += `${errorMessage}\n`)
        );
      }
      errorMessage = error.response.data.message;

      notification.error({
        message: "Error completing request",
        description: errorMessage,
      });
    }
  } else
    notification.error({
      message: "Error fetching data",
      description: "Please check your network and try again",
    });
}

/***
 *
 * validate password
 * returns validated password
 *@param {input string}
 * ****/
export const validatePwd = (rule, value) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      await reject("Password must be entered");
    } else if (value.length < 6) {
      await reject("Password length cannot be less than 6 digits");
    } else if (value.length > 12) {
      await reject("Password length cannot be greater than 12 digits");
    } else if (
      !/((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/.test(
        value
      )
    ) {
      await reject(
        "Password  must contain a special character, a small letter and a capital letter"
      );
    } else {
      await resolve();
    }
  });
};

/***
 *
 * validate email
 *returns validated email
 *@param {input string}
 * ****/
export const validateEmail = (rule, value) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      await reject("Email must be entered");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    ) {
      await reject("This is not a valid email");
    } else {
      await resolve();
    }
  });
};

/***
 *
 * validate phone
 *returns validated phone number
 *@param {input with number type}
 * ****/
export const validatePhone = (rule, value) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      await reject("Phone Number must be entered");
    } else if (
      !/^\(?([0-9]{4})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)
    ) {
      await reject("This is not a valid phone number");
    } else if (value.length > 11) {
      return;
    } else {
      await resolve();
    }
  });
};

/***
 *
 * validate name
 * * returns validated password
 *@param {input string}
 * ****/
const validateName = (rule, value) => {
  return new Promise(async (resolve, reject) => {
    if (!value) {
      await reject("Name must be entered");
    } else {
      await resolve();
    }
  });
};

/***
 *
 * validate form on submit
 *returns validated password and email on form submit
 *@param {input values}
 * ****/
const formValidation = (values) => {
  const emailCheck =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  let mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );
  //valid email check
  if (!emailCheck.test(String(values.email).toLowerCase())) {
    values = {
      ...values,
      emailError: `${values.email}is not a valid email`,
    };
  }
  if (mediumPassword.test(values.password)) {
    values = {
      ...values,
      passwordReturn: "MEDIUM",
    };
  } else if (strongPassword.test(values.password)) {
    values = {
      ...values,
      passwordReturn: "STRONG",
    };
  } else {
    values = {
      ...values,
      passwordReturn: "WEAK",
    };
  }

  return values;
};

/**
 * extractQueryParams
 * Returns an object of the query string
 * @param {String} qString
 * @return {Object} Object
 **/
export const extractQueryParams = (qString) => {
  if (!qString) return;
  if (typeof qString !== "string") {
    throw new Error(
      `Expected qString to be of type ${String}, but got ${typeof qString}`
    );
  }

  let splitStr = qString.substring(1).split("&");
  let result = {};

  splitStr.forEach((item) => {
    let itemList = [];

    if (item.includes("api_key")) {
      itemList.push(item.substring(0, 7));
      itemList.push(item.slice(8));
      result[itemList[0]] = itemList[1];
    } else {
      itemList = item.split("=");
      result[itemList[0]] = itemList[1];
    }
  });

  return result;
};

// export const formatHtml = (html) => {
//   const text = convert(html, {
//     wordwrap: false,
//   });

//   return text;
// };
