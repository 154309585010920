import React from "react";
import { FlexibleDiv } from "../../../components/Box/styles";
import { Typography } from "antd";
import Button from "../../../components/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Davido from "../../../assets/pngs/davido-thanks.png";
import Signup from "..";

const Confirmation = ({ children }) => {
  const location = useLocation;
  const navigate = useNavigate();

  return (
    <Signup>
      <FlexibleDiv>
        <FlexibleDiv
          flexWrap="nowrap"
          justifyContent="space-between"
          flexDir="column"
          className="confirmation_box"
        >
          <FlexibleDiv>
            <img src={Davido} alt="" />
          </FlexibleDiv>
          <Typography.Title level={3}>Thank You</Typography.Title>

          <span>
            Your purchase has been successful! You should receive an email
            confirmation from us shortly. <br /> <br />
            Please Login to your account and leave us a message on the live
            stream comment board.
          </span>
          <FlexibleDiv margin="40px 0">
            <Button
              width="180px"
              height="40px"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </Signup>
  );
};

export default Confirmation;
