import gsap from "gsap";
import splitting from "splitting";
import React, { useEffect, useState } from "react";
import { LineupsWrap } from "./styles";
import { useSwipeable } from "react-swipeable";
import { carouselData } from "../../../utils/dataHelper";
import { FlexibleDiv } from "../../../components/Box/styles";
import useRefArray from "../../../hooks/useRefArray";
import {
	next,
	previous,
} from "../../../animations/LineUps/carousel_animations";

const LineUps = ({ passScrollEventDown, setPassScrollEventDown }) => {
	const infoRefs = useRefArray(5);
	const imageRefs = useRefArray(5);
	const titleRefs = useRefArray(5);
	const [current, setCurrent] = useState(0);
	const [position, setPosition] = useState(25);
	const [isScrolling, setIsScrolling] = useState(false);
	const [scroll, setScroll] = useState({ state: false, from: "up" });

	useEffect(() => {
		splitting({ by: "words", target: "#line_ups .static_text" });
		splitting({ target: "#line_ups [data-splitting]:not(.static_text)" });
	}, []);

	useEffect(() => {
		if (passScrollEventDown) {
			if (scroll.from === "up")
				next(current, 1.25, infoRefs, imageRefs, titleRefs);
			else if (scroll.from === "down")
				previous(current, 1.25, infoRefs, imageRefs, titleRefs);
			setTimeout(() => {
				setScroll((prevState) => ({ ...prevState, state: true }));
			}, 2000);
		} else {
			setTimeout(() => {
				if (
					infoRefs.length > 0 &&
					imageRefs.length > 0 &&
					titleRefs.length > 0
				)
					reset();
			}, 1000);
		}
	}, [infoRefs, imageRefs, titleRefs, passScrollEventDown]);

	const reset = () => {
		infoRefs.forEach((ref) => {
			gsap.set(ref.current, { opacity: 0 });
		});
		if (scroll.from === "up") {
			imageRefs.forEach((ref) => {
				gsap.set(ref.current, { clipPath: "inset(0% 0% 100% 0%)" });
			});
			titleRefs.forEach((ref) => {
				const el = gsap.utils.selector(ref.current);
				gsap.set(el(".word > .char, .whitespace"), {
					yPercent: window.innerWidth > 800 ? 100 : 200,
				});
			});
		} else if (scroll.from === "down") {
			imageRefs.forEach((ref) => {
				gsap.set(ref.current, { clipPath: "inset(100% 0% 0% 0%)" });
			});
			titleRefs.forEach((ref) => {
				const el = gsap.utils.selector(ref.current);
				gsap.set(el(".word > .char, .whitespace"), {
					yPercent: window.innerWidth > 800 ? -100 : -200,
				});
			});
		}
	};

	const handleScroll = async (e) => {
		if (
			!scroll.state ||
			isScrolling ||
			!passScrollEventDown ||
			e.deltaY === -0
		)
			return;
		setIsScrolling(true);

		if (e.deltaY > 0 && current < 4) {
			setPosition(position + 10);
			setCurrent((prevState) => {
				const newState = prevState + 1;
				next(newState, 0.25, infoRefs, imageRefs, titleRefs);
				return newState;
			});
		} else if (e.deltaY < 0 && current > 0) {
			setPosition(position - 10);
			setCurrent((prevState) => {
				const newState = prevState - 1;
				previous(newState, 0.25, infoRefs, imageRefs, titleRefs);
				return newState;
			});
		} else if (current <= 0) {
			setScroll(() => ({ from: "up", state: false }));
			setPassScrollEventDown(false);
		} else if (current >= 4) {
			setScroll(() => ({ from: "down", state: false }));
			setPassScrollEventDown(false);
		}

		setTimeout(() => {
			setIsScrolling(false);
		}, 1500);
	};

	const swipeHandlers = useSwipeable({
		onSwiped: (e) => {
			if (
				!scroll.state ||
				isScrolling ||
				!passScrollEventDown ||
				e.deltaY === -0
			)
				return;
			setIsScrolling(true);

			if (e.dir === "Up" && current < 4) {
				setPosition(position + 10);
				setCurrent((prevState) => {
					const newState = prevState + 1;
					next(newState, 0.25, infoRefs, imageRefs, titleRefs);
					return newState;
				});
			} else if (e.dir === "Down" && current > 0) {
				setPosition(position - 10);
				setCurrent((prevState) => {
					const newState = prevState - 1;
					previous(newState, 0.25, infoRefs, imageRefs, titleRefs);
					return newState;
				});
			} else if (current <= 0) {
				setScroll(() => ({ from: "up", state: false }));
				setPassScrollEventDown(false);
			} else if (current >= 4) {
				setScroll(() => ({ from: "down", state: false }));
				setPassScrollEventDown(false);
			}
			setTimeout(() => {
				setIsScrolling(false);
			}, 1500);
		},
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	});

	return (
		<LineupsWrap
			id="line_ups"
			{...swipeHandlers}
			position={position}
			className="home_section"
			onWheel={(e) => handleScroll(e)}
		>
			<FlexibleDiv className="carousel__section">
				<FlexibleDiv height="100%">
					<FlexibleDiv
						justifyContent="flex-start"
						width="90%"
						height="100%"
						flexWrap="nowrap"
					>
						<FlexibleDiv
							width="max-content"
							justifyContent="flex-start"
							maxWidth="270px"
							alignItema="flex-start"
							className="left_section"
						>
							<p data-splitting="" className="static_text">
								Over a Decade of hits
							</p>
							<div className="celeb_info">
								<div className="celeb_titles">
									{carouselData.map((item, index) => (
										<h2
											key={index}
											data-splitting=""
											ref={titleRefs[index]}
										>
											{item.name}
										</h2>
									))}
								</div>
								{carouselData.map((item, index) => (
									<div ref={infoRefs[index]} key={index}>
										<span>{item.body}</span>
										<br />
										<br />
										<span>{item.subBody}</span> <br />
										<span>{item.lyricTwo}</span> <br />
										<span>{item.lyricThree}</span>
									</div>
								))}
							</div>
						</FlexibleDiv>
						<FlexibleDiv
							className="celeb_images"
							width="50%"
							height="100%"
						>
							{carouselData.map((item, index) => (
								<div key={index} className="image_wrap">
									<img
										src={item.image}
										ref={imageRefs[index]}
										alt=""
									/>
								</div>
							))}
						</FlexibleDiv>
					</FlexibleDiv>
					{window.innerWidth > 800 &&
						carouselData.map((item, index) => (
							<h1
								key={index}
								data-splitting=""
								ref={titleRefs[index]}
							>
								{item.name}
							</h1>
						))}
				</FlexibleDiv>
			</FlexibleDiv>
		</LineupsWrap>
	);
};

export default LineUps;
