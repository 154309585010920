import { Typography } from "antd";
import React, { useState } from "react";
import { FlexibleDiv } from "../../components/Box/styles";
import LandingLayout from "../../components/Layout";
import { FAQWrapper } from "./styles";
import Image from "../../assets/gifs/faq.gif";
import { faqs } from "../../utils/dataHelper";

const FAQ = () => {
  const [activeTab, setActivetab] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const sections = ["Stream Passes", "MTN", "Watching the Stream", "Technical"];

  return (
    <LandingLayout>
      <FAQWrapper>
        <FlexibleDiv height="100%">
          <FlexibleDiv
            width="90%"
            justifyContent="space-between"
            className="top_wrap"
          >
            <FlexibleDiv
              className="left_section"
              flexDir="column"
              alignItems="flex-start"
            >
              <Typography.Title>FAQ’s</Typography.Title>
              <p>We Rise By Lifting Others</p>
              <span>
                We know you have a lot of questions… We’ll do our best to handle
                everything in this section.
                <br />
                <br /> We’ll cover everything from house rules, available
                countries, stream passes, spray money and more. <br />
                <br /> To keep up to date on all things uduX please follow us
                @udux on social platforms
              </span>
            </FlexibleDiv>

            <FlexibleDiv className="right_section">
              <img src={Image} alt="" />
            </FlexibleDiv>
          </FlexibleDiv>
          <FlexibleDiv
            className="bottom_wrap"
            flexDir="column"
            justifyContent="flex-start"
          >
            <FlexibleDiv>
              {sections.map((num, idx) => (
                <FlexibleDiv
                  key={idx}
                  className="tabs"
                  width="max-content"
                  alignItems="flex-start"
                >
                  <p
                    className={activeSection === idx + 1 && "active"}
                    onClick={() => setActiveSection(idx + 1)}
                  >
                    {num}
                  </p>
                  {idx + 1 !== 4 && "/"}{" "}
                </FlexibleDiv>
              ))}
            </FlexibleDiv>

            {faqs.map(
              (item, idx) =>
                item.section === activeSection && (
                  <FlexibleDiv flexDir="column" key="idx">
                    <Typography.Title>{sections[idx]}</Typography.Title>
                    <FlexibleDiv width="80%" className="faq_contents">
                      {item.data.map((faq, id) => (
                        <>
                          <FlexibleDiv
                            flexDir="column"
                            alignItems="flex-start"
                            className={
                              id + 1 === activeTab ? "show" : undefined
                            }
                            onClick={() => setActivetab(id + 1)}
                            key={id}
                          >
                            <p>{faq.question}</p>
                            <span>{faq.answer}</span>
                          </FlexibleDiv>
                        </>
                      ))}
                      {activeSection === 1 && (
                        <FlexibleDiv
                          flexDir="column"
                          alignItems="flex-start"
                          className={
                            item.data.length + 1 === activeTab
                              ? "show"
                              : undefined
                          }
                          onClick={() => setActivetab(item.data.length + 1)}
                        >
                          <p>What are the different stream pass tiers?</p>
                          <span>
                            E Choke!; <br />
                            Covers the Live Stream event only Plus 1 month
                            <br />
                            free access to stream your favorite song on uduX
                            <br />
                            <br />
                            30 Billion Gang <br />
                            Covers the Live Stream event
                            <br />
                            Access to the WRBLO exclusive content releases such
                            as; A day in the life of Davido, making WRBLO (the
                            documentary) & behind the scenes rehearsal clips.{" "}
                            <br />
                            Plus Plus Access of replay the concert after the
                            livestream
                            <br />
                            <br />
                            ALAT ?<br />
                            An exclusive offering powered by one of our partners
                            WEMA Bank <br />
                            Powered by WEMA Bank <br />
                            All perks in the 30 Billion Gang stream pass <br />
                            Redeemable if you have an existing ALAT account or
                            successfully create a new one
                          </span>
                        </FlexibleDiv>
                      )}

                      {activeSection === 4 && (
                        <FlexibleDiv
                          flexDir="column"
                          alignItems="flex-start"
                          className={
                            item.data.length + 1 === activeTab
                              ? "show"
                              : undefined
                          }
                          onClick={() => setActivetab(item.data.length + 1)}
                        >
                          <p>
                            I am experiencing difficulties accessing my account.
                            What can I do?
                          </p>
                          <span>
                            Send us an email detailing the issues you are
                            experiencing (preferably with screenshots) at{" "}
                            <a target="_blank" href="mailto:buddy@groove.ng">
                              buddy@groove.ng
                            </a>
                            . Or send us a dm on{" "}
                            <a
                              target="_blank"
                              href="https://twitter.com/udux_music?s=21"
                            >
                              twitter
                            </a>{" "}
                            or{" "}
                            <a
                              target="_blank"
                              href="https://www.instagram.com/uduxmusic/?utm_medium=copy_link"
                            >
                              Instagram
                            </a>
                            . We will help out as quickly as we can.
                          </span>
                        </FlexibleDiv>
                      )}
                    </FlexibleDiv>
                  </FlexibleDiv>
                )
            )}
          </FlexibleDiv>
        </FlexibleDiv>
      </FAQWrapper>
    </LandingLayout>
  );
};

export default FAQ;
