import React, { useState, useEffect } from "react";
import { AuthWrap } from "./styles";
import { FlexibleDiv } from "../../components/Box/styles";
import LandingLayout from "../../components/Layout";
import { Typography, Progress } from "antd";
import Logo from "../../assets/svgs/uduxLogo.svg";
import { Link, useLocation } from "react-router-dom";
import Confirm from "../../assets/pngs/confirm.png";

const Signup = ({ children }) => {
  const [borderPosition, setBorderPosition] = useState("0");
  const [activePayment, setActivePayment] = useState("Paypal");
  const [activePlan, setActivePlan] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/signup") {
      setBorderPosition("0");
    } else if (location.pathname === "/payments") {
      setBorderPosition("50");
    } else if (location.pathname === "/payment-confirmation") {
      setBorderPosition("100");
    }
  }, [setBorderPosition]);

  useEffect(() => {
    const active_plan = JSON.parse(localStorage.getItem("active_plan"));
    setActivePlan(active_plan);
  }, []);

  const tabs = ["User Details", "Payments", "Confirmation"];

  return (
    <LandingLayout>
      <AuthWrap borderPosition={borderPosition}>
        <FlexibleDiv height="100%" flexWrap="nowrap" alignItems="flex-start">
          <FlexibleDiv
            width="55%"
            flexDir="column"
            height="100%"
            alignItems="flex-start"
            className="left_section"
            flexWrap="nowrap"
          >
            <FlexibleDiv justifyContent="flex-end">
              <img src={Logo} alt="" className="logo" />
            </FlexibleDiv>

            <FlexibleDiv flexDir="column" margin="30px 0 0 0">
              <FlexibleDiv justifyContent="space-between" maxWidth="370px">
                {tabs.map((item, idx) => (
                  <span key={idx}>{item}</span>
                ))}
              </FlexibleDiv>
              <FlexibleDiv width="300px" flexDir="column">
                <FlexibleDiv className="progressWrap">
                  <Progress percent={borderPosition} showInfo={false} />
                </FlexibleDiv>
                <FlexibleDiv justifyContent="space-between">
                  <p
                    className={
                      location.pathname === "/signup" && "activeNumber"
                    }
                  >
                    1
                  </p>
                  <p className={tabs === "/payments" && "activeNumber"}>2</p>
                  <p
                    className={
                      tabs === "/payment-confirmation" && "activeNumber"
                    }
                  >
                    3
                  </p>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
            {children}
          </FlexibleDiv>
          <FlexibleDiv width="45%" className="righ_section" flexDir="column">
            {location.pathname === "/payment-confirmation" ? (
              <Typography.Title>
                05.03.2022 <br />
                <span>WRLBO2</span>
              </Typography.Title>
            ) : (
              <Typography.Title>
                My WRLBO2 <br />
                <span>Pass</span>
              </Typography.Title>
            )}
            <FlexibleDiv width="80%" className="confirmImage">
              <img src={Confirm} alt="" />
            </FlexibleDiv>
            <Typography.Title level={3}>
              {activePlan?.plan}
              <br />
              {activePlan?.currency === "NGN" ? (
                <Typography.Title level={3}>
                  &#8358;{activePlan?.price}
                </Typography.Title>
              ) : (
                <Typography.Title level={3}>
                  {activePlan?.sign + activePlan?.price}
                </Typography.Title>
              )}
            </Typography.Title>
            <span>
              You’ve chosen your stream pass.. Now fill in a few details so you
              can turn up with OBO at one of the biggest events of the year.
            </span>
          </FlexibleDiv>
        </FlexibleDiv>
      </AuthWrap>
    </LandingLayout>
  );
};

export default Signup;
