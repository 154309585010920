import React, { useState } from "react";
import { FlexibleDiv } from "../../components/Box/styles";
import { notification } from "antd";
import Button from "../../components/Button";
import { getCurrentUser } from "../../network/user";
import { CURRENT_USER } from "../../context/types";
import { SmileOutlined } from "@ant-design/icons";
import { stripeClientSecret } from "../../network/user";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

function StripeCheckout({ user, topupValue, dispatch, setShowPaymentForm }) {
  const stripe = useStripe();
  const elements = useElements();
  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const stripePayMoney = async (e) => {
    e.preventDefault();
    if (!stripe) {
      return;
    }
    setPaymentLoading(true);

    try {
      const clientPayload = {
        amount: topupValue?.amount,
        currency: "USD",
        plan: "any",
        userId: user?._id,
        wallet: true,
      };
      const res = await stripeClientSecret(clientPayload);
      const paymentResult = await stripe.confirmCardPayment(res?.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      if (paymentResult.error) {
        notification.open({
          message: "Error",
          description: paymentResult.error.message,
          icon: <SmileOutlined style={{ color: "red" }} />,
        });
        setPaymentLoading(false);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          const data = await getCurrentUser();
          dispatch({ type: CURRENT_USER, payload: data });
          setShowPaymentForm(false);
          setPaymentLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      setPaymentLoading(false);
    }
  };

  return (
    <FlexibleDiv className="stripe">
      <form onSubmit={stripePayMoney}>
        <CardElement />
        <Button htmlType="submit" loading={isPaymentLoading}>
          Topup
        </Button>
      </form>
    </FlexibleDiv>
  );
}
export default StripeCheckout;
