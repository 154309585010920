import styled from "styled-components";

export const LivestreamWrap = styled.div`
  width: 100%;
  height: 110vh;
  background: #1d1d1d;

  & + canvas {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 999999999;
  }

  @media (max-width: 1070px) {
    height: max-content;
    .countDown {
      h1 {
        font-size: 50px;
        color: #fcb316;
      }
    }
  }

  .header {
    height: 60px;
    background: #000;
    min-height: 60px;
    border-bottom: 1px solid #707070;
    padding: 0 15px;

    img {
      width: 70px;
    }
    h4 {
      margin: 0 0 -10px 20px;
      color: #fff;
      a {
        color: #fcb316;
        text-decoration: underline;
        text-decoration-color: #fcb31699;
      }
    }
  }
  .countDown {
    h1 {
      font-size: 70px;
      color: #fcb316;
    }
  }
  .video__section {
    max-width: 800px;
    border: 0.1em solid #fdb91399;
    height: 100%;
    padding: 10px;

    iframe {
      width: 100%;
      height: 500px;
    }
    .header_wrap {
      margin: 10px 0;
      span {
        font-weight: bold;
        font-size: 18px;
        color: #fff;
      }
      .dot {
        color: #ed1651;
        font-size: 14px;
        margin: 0 15px 0 -2px;
      }
      .sprayText {
        font-size: 12px;
        margin-right: 10px;
      }
      img {
        width: 70px;
        height: auto;
        cursor: pointer;
      }

      @media (max-width: 400px) {
        span {
          font-size: 14px;
        }
        .dot {
          margin: 0 5px 0 -2px;
        }
      }
    }
  }
  .chat__section {
    width: 33%;
    min-width: 350px;
    background: url("https://wrblo-live-fe.s3.us-east-2.amazonaws.com/square-blocks.png");
    background-size: cover;
    background-position: center;
    align-items: flex-end;
    @media (min-width: 1070px) {
      margin-top: -120px;
    }
    .chat_header {
      height: 57px;
      justify-content: flex-start;
      padding: 0 0 0 10px;
      background: #000;
      border-bottom: none;

      img {
        width: 70px;
        margin-right: 10px;
      }
      & > div {
        padding: 0 7px;
        background: #1d1d1d;
        border-left: 3px solid #fcb316;
        height: 100%;
        overflow: hidden;

        h4 {
          color: #fff;
          font-size: 7px;
        }
        h5 {
          font-size: 10px;
          white-space: nowrap;
          color: #fff;
          margin-bottom: 0;
          @media (max-width: 408px) {
            font-size: 9px;
          }
        }
        .makeItRain {
          color: #fcb316;
          margin-right: 5px;
          @media (max-width: 380px) {
            display: none;
          }
        }
        .name {
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @media (max-width: 1070px) and (min-width: 500px) {
            max-width: 250px;
          }
          @media (max-width: 380px) {
            max-width: 140px;
          }
        }
        .amount {
          max-width: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @media (max-width: 1070px) and (min-width: 500px) {
            max-width: max-content;
          }
        }
      }
    }
    .chat_main {
      width: 95%;
      height: max-content;

      /* height: 95%; */
      .chat_body {
        border: 0.1em solid #fdb91399;
        /* height: calc(100% - 57px); */
        height: max-content;
      }
      .videoItem {
        border-top: 0.1em solid #fdb91399;
        padding: 0 10px;
        min-height: 110px;
        cursor: pointer;
        transition: all 0.3s ease;

        :hover {
          background: #00000099;
        }
        .imageWrap {
          margin-right: 10px;
          img {
            width: 100px;
            height: auto;
            @media (max-width: 430px) {
              width: 80px;
            }
          }
        }
        .text__wrap {
          span {
            color: #fdb913;
          }
          .white {
            color: #fff;
            font-size: 12px;
            margin-top: 5px;
            @media (max-width: 430px) {
              font-size: 10px;
            }
          }
        }
      }
      .playing {
        background: #00000077;
      }
    }
  }

  @media (max-width: 1070px) {
    /* padding: 6em 0 0 0; */
    height: max-content;

    .body_wrap {
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
    .video__section {
      max-width: 100%;
      padding-top: 0 !important;
      .header-wrap {
        padding: 0 1em;
      }
    }
    .chat__section {
      width: 100%;
      min-width: 100%;
      margin: 3em 0 0 0 !important;

      .chat_body {
        min-height: 500px;
        iframe {
          min-height: 500px;
        }
      }
    }
  }
  @media (max-width: 800px) {
    padding-top: 100px;

    .header {
      background: transparent;
      border-bottom: none;
      position: fixed;
      top: 0;
      z-index: 99999999;
      width: 80%;

      img {
        display: none;
      }
      h4 {
        font-size: 10px;
        margin: 0;
      }
    }
  }

  @media (max-width: 700px) {
    iframe {
      height: 400px !important;
    }
  }

  @media (max-width: 500px) {
    iframe {
      height: 240px !important;
    }
  }

  .highestSpray {
    background: #fcb316;
    padding: 15px 10px 4px 10px;
    border-radius: 4px 4px 0 0;
    align-items: flex-end;

    h3 {
      color: #fff;
      margin-bottom: 15px;
    }
    & > div {
      margin-left: 15px;
      h4 {
        margin: 0;
        padding: 2px 5px;
        background: #fff;
        font-size: 12px;
        border: 1px solid #707070;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      h5 {
        margin: 0 -12px 0 0;
        padding: 0 5px;
        background: #1d1d1d;
        font-size: 12px;
        border: 1px solid #707070;
        color: #fcb316;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    @media (max-width: 378px) {
      h3 {
        font-size: 10px;
      }
      & > div {
        h5,
        h4 {
          font-size: 8px;
          max-width: 110px;
        }
      }
    }
  }
`;

export const PopOverWrap = styled.div`
  .pop-content-wrap {
    padding: 30px 0;

    span {
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin: 0 0 20px 0;
    }
    h5 {
      color: #fff;
      text-align: center;
      font-family: "ModellicaMedium", sans-serif !important;
      margin: 0 0 10px 0;
      font-size: 14px;
    }

    h1 {
      width: 100%;
      color: #fff;
      text-align: center;
      font-size: 40px;
      margin: 20px 0;
      font-family: "ModellicaMedium", sans-serif !important;
      font-weight: bold;
    }
    small {
      text-align: center;
      font-size: 10px;
      color: #fcb316;
      font-weight: bold;
    }
    button {
      span {
        margin: 0;
      }
    }
  }

  .stripe {
    form {
      & > div {
        border: 1px solid #fcb31699;
        padding: 15px 1px;
        height: 50px;
        margin: 20px 0 40px 0;
        background: #fff;
      }
    }
  }
`;
