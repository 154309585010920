import gsap from "gsap";

export const arenaLiveIn = () => {
  const TL = gsap.timeline();
  const arena_live = gsap.utils.selector("#arena_live");
	TL.set("#arena_live", { visibility: "visible" })
		.set(arena_live("[data-splitting]"), { visibility: "visible" })
		.to("#arena_live", {
			delay: 0.35,
			opacity: 1,
			duration: 1,
			ease: "circ.out",
		})
		.from("#arena_live video", {
			ease: "expo.out",
			stagger: 0.4,
			duration: 1.25,
			clipPath: "inset(0% 100% 0% 0%)",
		})
    .from(arena_live(".davidoText, .davidoTextMobile"), {
      opacity: 0,
    }, window.innerWidth > 800 ? "-=1.25" : 1)
		.from(arena_live(".word, .whitespace"), {
			duration: 0.5,
			opacity: 0,
			yPercent: 75,
			stagger: 0.005,
			ease: "power2.out",
		}, 1);
};

export const arenaLiveOut = () => {
  const TL = gsap.timeline();
  const arena_live = gsap.utils.selector("#arena_live");
	TL.to("#arena_live", {
		opacity: 0,
		duration: 0.75,
		ease: "circ.in",
	})
  .set("#arena_live", { visibility: "hidden" })
  .set(arena_live("[data-splitting]"), { visibility: "hidden" });
};
