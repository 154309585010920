import StreamPass from "../assets/pngs/stream-pass.png";
import Alat from "../assets/gifs/alat.gif";
import EChoke from "../assets/gifs/echoke.gif";
import ThirtyBg from "../assets/gifs/30BG.gif";
import Bankuli from "../assets/pngs/banuli.jpeg";
import Adekunle from "../assets/pngs/adekunle.jpeg";
import Patoranking from "../assets/pngs/patoraning.jpeg";
import Concert from "../assets/pngs/concert_main.jpeg";
import Werise from "../assets/pngs/werise.jpeg";
import Ckay from "../assets/pngs/ckay.jpeg";
import Rehearsals from "../assets/pngs/rehearsals.jpeg";

// menu

export const sideBarenuItems = [
  {
    item: `WRBLO2`,
    to: "/",
    subRoutes: ["/"],
  },
  {
    item: `Stream Passes`,
    to: "/stream-passes",
    subRoutes: [],
  },
  {
    item: "Watch",
    to: "/watch",
    subRoutes: [],
  },
  {
    item: "FAQS",
    to: "/faqs",
    subRoutes: [],
  },
  {
    item: "PopRev",
    to: "/poprev",
    subRoutes: [],
  },
];
export const mtnMenuItems = [
  {
    item: `WRBLO2`,
    to: "/",
    subRoutes: ["/"],
  },
  {
    item: "Watch",
    to: "/watch",
    subRoutes: [],
  },
  {
    item: "FAQS",
    to: "/faqs",
    subRoutes: [],
  },
  {
    item: "PopRev",
    to: "/poprev",
    subRoutes: [],
  },
];

// stream pass
export const streamPassData = [
  {
    id: 3,
    region: "Africa",
    list: [
      {
        plan: "E Choke",
        image: EChoke,
        specs: [" Live Stream event only ", "1 month free access to uduX"],
        currency: "NGN",
        sign: "",
        price: "1000",
      },
      {
        plan: "30 Billion Gang ",
        image: ThirtyBg,
        specs: [
          "Live Stream event",
          " Access to the WRBLO exclusive content releases: A day in the life of Davido, making WRBLO (the documentary) & rehearsals",
          "On-demand stream access of the WRBLO content after the livestream ",
        ],
        currency: "NGN",
        sign: "",
        price: "1500",
      },
      // {
      //   plan: "ALAT!",
      //   image: Alat,
      //   specs: [
      //     "Powered by WEMA Bank",
      //     "All perks in the 30 Billion Gang stream pass",
      //     "Redeemable if you have an existing ALAT account or successfully create a new one ",
      //   ],
      //   currency: "NGN",
      //   sign: "",
      //   price: "500",
      // },
    ],
  },

  // {
  //   id: 1,
  //   region: "Europe",
  //   list: [
  //     {
  //       plan: "E Choke",
  //       image: EChoke,
  //       specs: [" Live Stream event only ", "1 month free access to uduX"],
  //       price: "4.99",
  //       currency: "USD",
  //       sign: "$",
  //     },
  //     {
  //       plan: "30 Billion Gang ",
  //       image: ThirtyBg,
  //       specs: [
  //         "Live Stream event",
  //         " Access to the WRBLO exclusive content releases: A day in the life of Davido, making WRBLO (the documentary) & rehearsals",
  //         "On-demand stream access of the WRBLO content after the livestream ",
  //       ],
  //       price: "5.99",
  //       currency: "USD",
  //       sign: "$",
  //     },
  //     // {
  //     //   plan: "ALAT!",
  //     //   image: Alat,
  //     //   specs: [
  //     //     "Powered by WEMA Bank",
  //     //     "All perks in the 30 Billion Gang stream pass",
  //     //     "Redeemable if you have an existing ALAT account or successfully create a new one ",
  //     //   ],
  //     //   price: "2.99",
  //     //   currency: "GBP",
  //     //   sign: "£",
  //     // },
  //   ],
  // },
  {
    id: 2,
    region: "America",
    list: [
      {
        plan: "E Choke",
        image: EChoke,
        specs: [" Live Stream event only ", "1 month free access to uduX"],
        price: "4.99",
        currency: "USD",
        sign: "$",
      },
      {
        plan: "30 Billion Gang ",
        image: ThirtyBg,
        specs: [
          "Live Stream event",
          " Access to the WRBLO exclusive content releases: A day in the life of Davido, making WRBLO (the documentary) & rehearsals",
          "On-demand stream access of the WRBLO content after the livestream ",
        ],
        price: "5.99",
        currency: "USD",
        sign: "$",
      },
      // {
      //   plan: "ALAT!",
      //   image: Alat,
      //   specs: [
      //     "Powered by WEMA Bank",
      //     "All perks in the 30 Billion Gang stream pass",
      //     "Redeemable if you have an existing ALAT account or successfully create a new one ",
      //   ],
      //   price: "3.99",
      //   currency: "USD",
      //   sign: "$",
      // },
    ],
  },
  // {
  //   id: 3,
  //   region: "Middle East/Others",
  //   list: [
  //     {
  //       plan: "E Choke",
  //       image: EChoke,
  //       specs: [" Live Stream event only ", "1 month free access to uduX"],
  //       price: "4.99",
  //       currency: "USD",
  //       sign: "$",
  //     },
  //     {
  //       plan: "30 Billion Gang ",
  //       image: ThirtyBg,
  //       specs: [
  //         "Live Stream event",
  //         " Access to the WRBLO exclusive content releases: A day in the life of Davido, making WRBLO (the documentary) & rehearsals",
  //         "On-demand stream access of the WRBLO content after the livestream ",
  //       ],
  //       price: "5.99",
  //       currency: "USD",
  //       sign: "$",
  //     },
  //     // {
  //     //   plan: "ALAT!",
  //     //   image: Alat,
  //     //   specs: [
  //     //     "Powered by WEMA Bank",
  //     //     "All perks in the 30 Billion Gang stream pass",
  //     //     "Redeemable if you have an existing ALAT account or successfully create a new one ",
  //     //   ],
  //     //   price: "3.99",
  //     //   currency: "USD",
  //     //   sign: "$",
  //     // },
  //   ],
  // },
];

// lineup carousel
export const carouselData = [
  {
    body: "Since he burst on to the global music scene in 2012 with “Dami Duro”, he hasn’t slowed down ",
    subBody:
      "O.B.O has cemented his place in our hearts and minds over the years with hit songs such as “Fall”, “If”, “Gobe” “Jowo”and “Aye” and the list goes on and on…",
    name: "The Baddest",
    image: "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/davido-carousel.png",
  },
  {
    body: "Davido has the Midas touch when it comes to collaborations, we were not disappointed in 2019 when he teamed up with Summer Walker to deliver the anthem of all anthems!",
    subBody: " “See when you looking through my eyes all you see is __________",
    name: "Summer Walker",
    image: "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/summer.png",
  },
  {
    body: "Which one should we start with? Lower body or Blow my mind. ",
    subBody:
      "We simply cannot choose! What we do know is that every time these two link it’s simply magic.",
    name: "Chris Brown",
    image: "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/chris-brown.png",
  },
  {
    body: "The Collab we never knew we needed. We’ll just leave it there. Adekunle and OBO cleaned house with ‘High’ in 2021! Normally o!",
    subBody: ` Hennessy 250  `,
    lyricTwo: "Ko ma lo ni titi (ah)",
    lyricThree: "Giuseppe Zanotti inna Maserati (ah)",
    name: "Adekunle Gold",
    image: "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/adekunle.png",
  },
  {
    body: "Even If you had never heard of Amapiano - “Champion Sound” introduced the South African underground genre to the entire world. ",
    subBody: ` To prove twice is nice - Focalistic and Davido released Ke Starr…  `,
    name: "Focalistic",
    image: "https://wrblo-live-fe.s3.us-east-2.amazonaws.com/focalistic.png",
  },
];

export const faqs = [
  {
    section: 1,
    data: [
      {
        question: "I’m based in _____ can I watch the stream?",
        answer:
          "Davido granted us global rights to access the livestream, this means that no matter what country you’re based in, your stream pass is valid.",
      },
      {
        question: "Who should purchase a Stream Pass?",
        answer:
          "Anyone, Anywhere that wants to watch the livestream and is not an MTN Nigeria subscriber.",
      },
      {
        question: "How do I buy a Stream Pass?",
        answer:
          "It’s really easy. Simply Click on the ‘Stream Pass’ button on the left hand side of the screen or the “Buy Now” button. You’ll be redirected to the purchase page - choose your preferred stream pass option and proceed through the payment process.",
      },
      {
        question: "Why are there different tiers available for Stream Pass?",
        answer:
          "Different strokes for different folks, depending on what kind of experience you’re open to or looking for we have three options available. ",
      },
      {
        question: "Why do the prices differ based on countries?",
        answer:
          "Dependent on things like VAT and costs in each country, we are unfortunately unable to offer a set price across all streams.",
      },
      {
        question: "Can I buy a stream pass for someone else? ",
        answer:
          "Why not?! We Rise By Lifting Others. All you need to do is create an account for said person and send over the login details. They’ll get an email confirmation once you do.",
      },
    ],
  },
  {
    section: 2,
    data: [
      {
        question: "When does the Concert Live Stream start?",
        answer: "5th March 2022, Time (TBD)",
      },
      {
        question:
          "Will I be charged any fee to access the Livestream website? ",
        answer: "No, access is free for MTN customers",
      },

      {
        question: "Out of Data during the Livestream?",
        answer: " Dial *131*1# to buy.1GB for N300, 6GB for N1500.",
      },
      {
        question: "What is the validity of these data bundles?",
        answer:
          "The 1GB for N300 has a validity of 24hrs while the 6GB for N1500 is valid for 7days.",
      },
      {
        question:
          "Can I access the concert Livestream portal with other bundles apart from these two?",
        answer:
          "Yes, you can access the portal with any other MTN access bundles ranging from daily, weekly and monthly bundles but these 2 bundles have been hugely discounted.",
      },
      {
        question: "Are these bundles open to all customers to buy?",
        answer:
          "These data bundles are available to all MTN customers to buys.",
      },
      {
        question:
          "How do I buy these bundles without going through the entire menu?",
        answer:
          "Simply dial *131*155# for the daily bundle or *131*143# for the weekly plan. ",
      },
    ],
  },
  {
    section: 3,
    data: [
      {
        question: "How do I Login?",
        answer:
          "Visit the Login page and enter the email address and password that you used to create the account.",
      },
      {
        question:
          "How do I livestream the show when it starts? Watch > Sign in to your account and you’ll be immediately redirected to the stream page",
        answer:
          "Alternatively, streaming pass links are sent to your email shortly after pass purchase. Follow the link to join the live stream when the show starts.",
      },
      {
        question: "Will I be notified when the live stream starts?",
        answer:
          "If you have successfully purchased a streaming pass, we will send you an email to notify you once the show starts.",
      },
      {
        question: "Can I watch the stream on multiple devices?",
        answer:
          "Only one device can be logged in per user at any given point in time. This means if you give someone else your login details and they use it during the livestream you’ll be immediately logged out. Guards your details jealously! ",
      },
      {
        question: "Will I be notified when the live stream starts?",
        answer:
          "We’ll be sending across emails in the buildup to the event, we can’t reveal everything just yet! But keep your eyes peeled",
      },
    ],
  },
  {
    section: 4,
    data: [
      {
        question: "I’ve forgotten the password for my account. What next? ",
        answer:
          "Go to the Login Page and click on “Forgot password” and enter the email linked to the stream pass purchase.",
      },
    ],
  },
];

export const formatMoney = (amount) => {
  if (!amount) return;

  return parseInt(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const continents = [
  {
    continent: "Africa",
    codes: [
      "AO",
      "BF",
      "BI",
      "BJ",
      "BW",
      "CD",
      "CF",
      "CG",
      "CI",
      "CM",
      "CV",
      "DJ",
      "DZ",
      "EG",
      "EH",
      "ER",
      "ET",
      "GA",
      "GH",
      "GM",
      "GN",
      "GQ",
      "GW",
      "KE",
      "KM",
      "LR",
      "LS",
      "LY",
      "MA",
      "MG",
      "ML",
      "MR",
      "MU",
      "MW",
      "MZ",
      "NA",
      "NE",
      "NG",
      "RE",
      "RW",
      "SC",
      "SD",
      "SH",
      "SL",
      "SN",
      "SO",
      "SS",
      "ST",
      "SZ",
      "TD",
      "TG",
      "TN",
      "TZ",
      "UG",
      "YT",
      "ZA",
      "ZM",
      "ZW",
    ],
    countries: [
      "Angola",
      "Burkina Faso",
      "Burundi",
      "Benin",
      "Botswana",
      "Democratic Republic of Congo",
      "Central African Republic",
      "Congo",
      "Cote D'Ivoire",
      "Cameroon",
      "Cape Verde",
      "Djibouti",
      "Algeria",
      "Egypt",
      "Western Sahara",
      "Eritrea",
      "Ethiopia",
      "Gabon",
      "Ghana",
      "Gambia",
      "Guinea",
      "Equatorial Guinea",
      "Guinea-bissau",
      "Kenya",
      "Comoros",
      "Liberia",
      "Lesotho",
      "Libyan Arab Jamahiriya",
      "Morocco",
      "Madagascar",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Malawi",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Reunion",
      "Rwanda",
      "Seychelles",
      "Sudan",
      "St. Helena",
      "Sierra Leone",
      "Senegal",
      "Somalia",
      "",
      "Sao Tome and Principe",
      "Swaziland",
      "Chad",
      "Togo",
      "Tunisia",
      "Tanzania",
      "Uganda",
      "Mayotte",
      "South Africa",
      "Zambia",
      "Zimbabwe",
    ],
  },
  {
    continent: "Middle East/Others",
    codes: [
      "AE",
      "AF",
      "AM",
      "AZ",
      "BD",
      "BH",
      "BN",
      "BT",
      "CC",
      "CN",
      "CX",
      "CY",
      "GE",
      "HK",
      "ID",
      "IL",
      "IN",
      "IO",
      "IQ",
      "IR",
      "JO",
      "JP",
      "KG",
      "KH",
      "KP",
      "KR",
      "KW",
      "KZ",
      "LA",
      "LB",
      "LK",
      "MM",
      "MN",
      "MO",
      "MV",
      "MY",
      "NP",
      "OM",
      "PH",
      "PK",
      "PS",
      "QA",
      "RU",
      "SA",
      "SG",
      "SY",
      "TH",
      "TJ",
      "TL",
      "TM",
      "TR",
      "TW",
      "UZ",
      "VN",
      "XD",
      "XE",
      "XS",
      "YE",
      "AS",
      "AU",
      "CK",
      "FJ",
      "FM",
      "GU",
      "KI",
      "MH",
      "MP",
      "NC",
      "NF",
      "NR",
      "NU",
      "NZ",
      "PF",
      "PG",
      "PN",
      "PW",
      "SB",
      "TK",
      "TO",
      "TV",
      "UM",
      "VU",
      "WF",
      "WS",
      "XX",
      "AQ",
      "BV",
      "GS",
      "HM",
      "TF",
    ],
    countries: [
      "Antarctica",
      "Bouvet Island",
      "South Georgia And The South Sandwich Islands",
      "Heard and Mc Donald Islands",
      "French Southern Territories",
      "United Arab Emirates",
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bangladesh",
      "Bahrain",
      "Brunei Darussalam",
      "Bhutan",
      "Cocos (Keeling) Islands",
      "China",
      "Christmas Island",
      "Cyprus",
      "Georgia",
      "Hong Kong",
      "Indonesia",
      "Israel",
      "India",
      "British Indian Ocean Territory",
      "Iraq",
      "Iran (Islamic Republic of)",
      "Jordan",
      "Japan",
      "Kyrgyzstan",
      "Cambodia",
      "North Korea",
      "Korea, Republic of",
      "Kuwait",
      "Kazakhstan",
      "Lao People's Democratic Republic",
      "Lebanon",
      "Sri Lanka",
      "Myanmar",
      "Mongolia",
      "Macau",
      "Maldives",
      "Malaysia",
      "Nepal",
      "Oman",
      "Philippines",
      "Pakistan",
      "Qatar",
      "Russian Federation",
      "Saudi Arabia",
      "Singapore",
      "Syrian Arab Republic",
      "Thailand",
      "Tajikistan",
      "Turkmenistan",
      "Turkey",
      "Taiwan",
      "Uzbekistan",
      "Viet Nam",
      "Yemen",
      "American Samoa",
      "Australia",
      "Cook Islands",
      "Fiji",
      "Micronesia, Federated States of",
      "Guam",
      "Kiribati",
      "Marshall Islands",
      "Northern Mariana Islands",
      "New Caledonia",
      "Norfolk Island",
      "Nauru",
      "Niue",
      "New Zealand",
      "French Polynesia",
      "Papua New Guinea",
      "Pitcairn",
      "Palau",
      "Solomon Islands",
      "Tokelau",
      "Tonga",
      "Tuvalu",
      "United States Minor Outlying Islands",
      "Vanuatu",
      "Wallis and Futuna Islands",
      "Samoa",
    ],
  },
  {
    continent: "Europe",
    codes: [
      "GE",
      "GG",
      "GR",
      "HR",
      "HU",
      "IE",
      "IM",
      "IT",
      "JE",
      "LI",
      "LT",
      "LU",
      "LV",
      "MC",
      "MD",
      "ME",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RS",
      "SE",
      "SI",
      "SJ",
      "SK",
      "SM",
      "TR",
      "UA",
      "VA",
      "XK",
      "AD",
      "AL",
      "AM",
      "AT",
      "AX",
      "AZ",
      "BA",
      "BE",
      "BG",
      "BY",
      "CH",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FO",
      "FR",
      "GB",
    ],
    countries: [
      "UK",
      "Andorra",
      "Albania",
      "Armenia",
      "Austria",
      "Azerbaijan",
      "Bosnia and Herzegovina",
      "Belgium",
      "Bulgaria",
      "Belarus",
      "Switzerland",
      "Cyprus",
      "Czech Republic",
      "Germany",
      "Denmark",
      "Estonia",
      "Spain",
      "Finland",
      "Faroe Islands",
      "France",
      "United Kingdom",
      "Georgia",
      "Gibraltar",
      "Greece",
      "Croatia",
      "Hungary",
      "Ireland",
      "Iceland",
      "Italy",
      "Kazakhstan",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Latvia",
      "Monaco",
      "Moldova, Republic of",
      "Macedonia",
      "Malta",
      "Netherlands",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "Russian Federation",
      "Sweden",
      "Slovenia",
      "Svalbard and Jan Mayen Islands",
      "Slovak Republic",
      "San Marino",
      "Turkey",
      "Ukraine",
      "Vatican City State (Holy See)",
    ],
  },

  {
    continent: "America",
    codes: [
      "AG",
      "AI",
      "AN",
      "AW",
      "BB",
      "BL",
      "BM",
      "BQ",
      "BS",
      "BZ",
      "CA",
      "CR",
      "CU",
      "CW",
      "DM",
      "DO",
      "GD",
      "GL",
      "GP",
      "GT",
      "HN",
      "HT",
      "JM",
      "KN",
      "KY",
      "LC",
      "MF",
      "MQ",
      "MS",
      "MX",
      "NI",
      "PA",
      "PM",
      "PR",
      "SV",
      "SX",
      "TC",
      "TT",
      "UM",
      "US",
      "VC",
      "VG",
      "VI",
      "AR",
      "BO",
      "BR",
      "CL",
      "CO",
      "EC",
      "FK",
      "GF",
      "GY",
      "PE",
      "PY",
      "SR",
      "UY",
      "VE",
      "US",
      "USA",
    ],
    countries: [
      "Antigua and Barbuda",
      "Anguilla",
      "Netherlands Antilles",
      "Aruba",
      "Barbados",
      "Bermuda",
      "Bahamas",
      "Belize",
      "Canada",
      "Costa Rica",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "Grenada",
      "Greenland",
      "Guadeloupe",
      "Guatemala",
      "Honduras",
      "Haiti",
      "Jamaica",
      "Saint Kitts and Nevis",
      "Cayman Islands",
      "Saint Lucia",
      "Martinique",
      "Montserrat",
      "Mexico",
      "Nicaragua",
      "Panama",
      "St. Pierre and Miquelon",
      "Puerto Rico",
      "El Salvador",
      "Turks and Caicos Islands",
      "Trinidad and Tobago",
      "United States Minor Outlying Islands",
      "United States",
      "Saint Vincent and the Grenadines",
      "Virgin Islands (British)",
      "Virgin Islands (U.S.)",
      "Argentina",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Falkland Islands (Malvinas)",
      "French Guiana",
      "Guyana",
      "Peru",
      "Paraguay",
      "Suriname",
      "Uruguay",
      "Venezuela",
    ],
  },
];

export const videoList = [
  {
    Episode: "1",
    thumbnail: Concert,
    videoUrl:
      "https://player.vimeo.com/video/689657872?h=6aa99d88af&color=f3b12d&byline=0&portrait=0",
    videoTitle: "Davido WRBLO O2 arena concert",
  },

  {
    Episode: "2",
    thumbnail: Ckay,
    videoUrl:
      "https://player.vimeo.com/video/691417524?h=11d19f9833&color=f3b12d&byline=0&portrait=0",
    videoTitle: `Ckay talks global acceptance and "La la" | WRBLO backstage interview`,
  },
  {
    Episode: "3",
    thumbnail: Adekunle,
    videoUrl:
      "https://player.vimeo.com/video/691353786?h=b964e4c465&color=f3b12d&byline=0&portrait=0",
    videoTitle: `Adekunle Gold & Pheelz discuss making "high" & favourite Davido love songs | WRBLO Backstage Interviews`,
  },
  {
    Episode: "4",
    thumbnail: Patoranking,
    videoUrl:
      "https://player.vimeo.com/video/691352088?h=d68dacfb23&color=f3b12d&byline=0&portrait=0",
    videoTitle: `Patoranking on his friendship with Davido & new music | WRBLO backstage interviews`,
  },
  {
    Episode: "5",
    thumbnail: Bankuli,
    videoUrl:
      "https://player.vimeo.com/video/691352534?h=f959d6c72d&color=f3b12d&byline=0&portrait=0",
    videoTitle: `Bankulli on Davido's music & growth | WRBLO backstage interviews`,
  },
  {
    Episode: "6",
    thumbnail: Werise,
    videoUrl:
      "https://player.vimeo.com/video/691855738?h=dbed139cdb&color=f3b12d&title=0&byline=0&portrait=0",
    videoTitle: `We Rise By Lifting Others: The Documentary`,
  },

  {
    Episode: "7",
    thumbnail: Rehearsals,
    videoUrl:
      "https://player.vimeo.com/video/691360478?h=1088477aed&color=f3b12d&byline=0&portrait=0",
    videoTitle: `Davido WRBLO Concert Rehearsals with the Composerz`,
  },
];
